.fullCartContainer {
  margin-top: 5px;
  width: 100%;
  background-color: white;
  box-shadow: 0px 10px 60px rgb(226 236 249 / 50%);
  height: calc(100vh - 130px);
  border-radius: 10px 0 0 10px;
  display: flex;
  overflow: hidden;
}

.actionToClose {
  width: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-right: 1px solid rgba(222, 223, 228, 1) !important; */
  border-right: 1px solid #8934ff4f !important;
  border-radius: 10px 0 0 10px;
  color: rgb(182, 185, 202);
  cursor: pointer;
}
.actionToClose:hover {
  background: rgb(248, 246, 255);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 15px;
  color: #8834ff;
}

.cartMainBody {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.cartMainContainer {
  position: relative;
  height: calc(100vh - 185px);
}

.cartCheckoutContainer {
  position: absolute;
  bottom: 0;
  padding: 0.3rem 1rem;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  gap: 20px;
}

.otherCartPart {
  width: 30%;
  height: calc(100vh - 235px);
  overflow: auto;
  /* display: grid;
  grid-template-rows: 1fr 1fr; */
}

.otherCartPart::-webkit-scrollbar {
  width: 0.3em;
}

.otherCartPart::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.otherCartPart::-webkit-scrollbar-thumb {
  background-color: rgba(178, 183, 203, 1);
  outline: 1px solid rgba(222, 223, 228, 1);
  border-radius: 6px;
}

/* .cartInputPartContainer {
  height: 100%;
  overflow-y: auto;
}
.cartInputPartContainer::-webkit-scrollbar {
  width: 0.3em;
}

.cartInputPartContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.cartInputPartContainer::-webkit-scrollbar-thumb {
  background-color: rgba(178, 183, 203, 1);
  outline: 1px solid rgba(222, 223, 228, 1);
  border-radius: 6px;
} */

/* .cartPaymentPartContainer {
  height: 100%;
  overflow-y: auto;
}

.cartPaymentPartContainer::-webkit-scrollbar {
  width: 0.3em;
}

.cartPaymentPartContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.cartPaymentPartContainer::-webkit-scrollbar-thumb {
  background-color: rgba(178, 183, 203, 1);
  outline: 1px solid rgba(222, 223, 228, 1);
  border-radius: 6px;
} */

@media screen and (max-width: 991px) {
  .fullCartContainer {
    margin-top: 0;
    height: 100vh;
    border-radius: 0;
  }

  .cartMainContainer {
    height: calc(100vh - 55px);
  }

  .actionToClose {
    display: none;
  }

  .otherCartPart {
    height: calc(100vh - 105px);
  }
}

@media screen and (max-width: 767px) {
  .cartMainContainer {
    overflow-y: auto;
    position: static;
  }
  .cartMainBody {
    height: 100%;
    overflow: visible;
  }

  .otherCartPart {
    width: 100%;
    height: 100% !important;
    display: block;
    margin-top: 20px;
  }

  .cartCheckoutContainer {
    display: none;
  }
}

@media screen and (max-height: 721px) {
  .fullCartContainer {
    margin-top: 0;
    height: 100vh;
    border-radius: 0;
  }

  .cartMainContainer {
    height: calc(100vh - 55px);
  }

  .actionToClose {
    display: none;
  }

  .otherCartPart {
    height: calc(100vh - 105px);
  }
}
