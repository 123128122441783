.filterToggleButton {
  height: 40px;
}

.filterToggleButtonLink {
  text-decoration: none !important;
}

.addButton {
  height: 40px;
  width: auto;
}
.addButtonLink {
  padding: 0 0.5rem !important;
}
@media screen and (max-width: 575px) {
  .filterToggleButton {
    width: 44px;
    height: 40px;
  }
  .addButton {
    width: 44px !important;
    height: 40px !important;
  }
}
@media screen and (min-width: 992px) {
  .addButton {
    width: calc(100% - 10px) !important;
  }
  .filter{
    margin-left: -11px;
  }
}
@media screen and (width > 350px) and (width <= 375px) {

  .filter{
    margin-left: -11px;
  }
}
@media screen and (width > 380px) and (width <= 420px) {

  .filter{
    margin-left: -8px;
  }
}
@media screen and (width > 310px) and (width <= 350px) {

  .filter{
    margin-left: -15px;
  }
  .addbtn{
    margin-left: 4px !important;
  }
}
