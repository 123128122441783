.bottomDiv {
  display: flex;
  justify-content: center;
  height: 40px;
  position: fixed;
  bottom: 0%;

  width: 100%;
  opacity: 1;
  margin-bottom: 10px;
}

.accordionCardBody {
  overflow: auto;
  height: calc(100vh - 305px) !important;
}
.accordion_item {
  border-bottom: 1px solid #dee2e6;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}
.radio[type="radio"] {
  border-radius: 50% !important;
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
}
.radio:checked[type="radio"] {
  background-image: url(../assets/images/selecttick.png) !important;
  border-radius: 0px !important;
  border: none !important;
  background-color: white !important;
}
.form-check-label {
  color: blueviolet;
}
