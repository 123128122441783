.formMain {
  padding: 0 3rem !important;
}

/* :local(.multiSelect) :global(.react-select-container__value-container) {
  overflow: auto;
}
:local(.multiSelect)
  :global(.react-select-container__value-container)::-webkit-scrollbar {
  width: 0.3em;
}
:local(.multiSelect)
  :global(.react-select-container__value-container)::-webkit-scrollbar-thumb {
  background-color: rgba(178, 183, 203, 1);
  outline: 1px solid rgba(222, 223, 228, 1);
  border-radius: 6px;
}
:local(.multiSelect)
  :global(.react-select-container__value-container)::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
} */

@media screen and (max-width: 768px) {
  .formMain {
    padding: 0 1rem !important;
  }
}
