:local(.section) {
    display: block;
    width: 100%;
    border: 1px solid #DEDFE4;
    box-sizing: border-box;
    border-radius: 6px;
    margin: 10px auto;
    padding: 15px;
    text-align: center;
}
:local(.section) :global(label){
    width: 100%;
    background-color: #dedfe47a;
    border: 1px dashed #DEDFE4;
    box-sizing: border-box;
    border-radius: 6px;
    margin: auto;
    padding: 3rem 15px;
    cursor: pointer;
    text-align: center;
    
}
:local(.section) :global(span){
    margin: 10px 0px;
    font-size: 12px;
}

:local(.image) {
    margin: 0.5rem 0.5rem;
    position: relative;
    box-shadow: rgba(0,0,0,0.05) 0px 1px 2px 0px;
    width: 10rem;
    height: 10rem;
    border-radius: 5px;
    
    border: 0.5px solid rgba(0,0,0,0.12);
}
:local(.givenStyles){
    width: 5rem;
    height: 5rem;
}
:local(.image) :global(.icon){
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    background-color: lightcoral;
    color: white;
    height: 20px;
    width: 20px;
}

:local(.image) :global(.tag){
    position: absolute;
    top: -0.7rem;
    left: 5px;
    background: rgb(67, 42, 211);
    border-radius: 0 0 50% 50%;
    color: rgb(255, 255, 255);
    height: 20px;
    padding: 0 18px 0 15px;
    text-decoration: none;
}

:local(.image) :global(.img){
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
:local(.image) :global(.icon):hover{
    background-color: red;
}

:local(.image) :global(img){
    border-radius: 2px;
    margin: 0;
}

.minWidth{
    min-width: 100%;
    display: flex;
    justify-content: center;
}


:global(.row) :global(.grabbable){
    max-width: 10rem !important;
    padding: 0;
    
}

.border {
    border: 1px dashed #DEDFE4;
    border-radius: 6px;
    gap: 15px
}

.browseTxt {
    color: rgba(89, 50, 234, 1);
    font-size: 14px !important;
}

.txt {
    font-size: 14px;
}

.txtSize {
    font-size: 12px;
    display: block;
}