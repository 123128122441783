@media all {
    .pageBreak {
        display: none;
    }
}

/* @media print {
    html,
    body{
        height: initial !important;
        overflow: initial !important; 
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }
} */

@media print {
    
    .pageBreak{
        page-break-before: always;
        /* margin-top: 1rem;
        display: block;
        page-break-before: auto; */
    }
    .barcode {
        margin-top: 3pt;
        font-size:  4vw;
        /* size: 2in 1in; 
        size: fit-content; 
        page-break-before: always;
        width: auto;
        height: fit-content;
        block-size: fit-content; */
    }
}
.barcodeTitleText{
    width: 279px;
    text-overflow: ellipsis;
    overflow: hidden;
}

