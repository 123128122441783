.gridContainer:not(:global(.smallGrid)) {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, 180px);
  justify-content: center;
}

.gridContainer:global(.smallGrid) {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, 100px);
  justify-content: center;
}
