.modalDialog :global(.table){
    margin-bottom: 0 !important;
}

/* .modalDialog :global(.modal-content){
    max-height: calc(100vh - 100px) !important;
} */

.tableContainer{
    /* max-height: calc(100vh - 220px) !important; */
    /* max-height: calc(100vh - 320px) !important; */
    max-height: calc(100vh - 320px) !important;

    width: 100%;
    /* height: 85% !important; */
}
.tableContainer::-webkit-scrollbar {
    width: 0.3em;
  }
  .tableContainer::-webkit-scrollbar-thumb {
    background-color: rgba(178, 183, 203, 1);
    outline: 1px solid rgba(222, 223, 228, 1);
    border-radius: 6px;
  }
  .tableContainer::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

@media screen and (max-width: 575px) {
    .modalDialog :global(.modal-content){
        height: 100vh !important;
    }
    .tableContainer{
      max-height: calc(100% - 65px) !important;
      height: 100% !important;
  }
  }
@media screen and (max-width: 500px) {
    .modalDialog :global(.modal-body){
        padding:0 !important;
    }
  }