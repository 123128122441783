
/* MEDIUM CARD DESIGN*/
.cardbody {
    text-align: center;
    width: 100%;
    border-radius: 6px !important;
    border: 0px !important;
    height: 100% !important;
    cursor: pointer;
    box-shadow: 0px 10px 60px rgb(226 236 249 / 50%);
  }
  
  .selectedItem {
    border: 1px solid #d957f9 !important;
    cursor: pointer;
    box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.25) !important;
  }
  
  .cardbody:focus {
    border: 1px solid #d957f9 !important;
  }
  .imagediv {
    /* width: 240px; */
    height: 155px;
  }
  .cardimg {
    text-align: center;
    /* width: 240px;
      height: 155px; */
    width: 100%;
    height: 100%;
    object-fit:scale-down ;
    border-radius: 6px 6px 0px 0px !important;
  }
  .cardContent {
    margin-top: 10px;
    height: calc(100% - 155px);
  }
  
  .active {
    border: 1px solid #d957f9 !important;
    cursor: pointer;
  }
  .disable {
    pointer-events: none;
    opacity: 1;
    border: 1px solid #d957f9 !important;
    box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.25) !important;
  }
  .disbleCheck {
    pointer-events: none;
  }
  
  .cardTitle {
    padding-left: 15px;
    padding-right: 15px;
    color: #141516 !important;
    font-weight: 700 !important;
    font-size: 16px !important;
}
.cardPrice{
    font-size: 16px !important;
  }
.cardAvailableItem{
    font-size: 16px !important;
    margin-bottom: 1rem !important;

  }
  .cardTitleWrap {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis !important;
    margin-bottom: 0 !important;
  }
  


  /* SMALL CARD DESIGN*/
  :global(.smallGrid) .imagediv {
    margin: 0 auto;
    width: 90px;
    height: 90px;
  }

  :global(.smallGrid) .cardContent {
    margin-top: 5px;
    height: calc(100% - 90px);
  }

  :global(.smallGrid) .cardTitle {
    padding-left: 8px;
    padding-right: 8px;
    font-weight: 700 !important;
    font-size: 14px !important;
  }

  :global(.smallGrid) .cardPrice{
    
    font-size: 13px !important;
    margin-bottom: 0px;

  }

  :global(.smallGrid) .cardAvailableItem{
    margin-bottom: 8px !important;
    font-size: 12px !important;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }