.wrapper {
  height: 90vh;
}

.backToLogin {
  text-align: center;
  font-weight: 400;
  font-size: 19.8px;
  font-family: osicons;
}
.passwordInput {
  position: relative;
}

/* Style for the password input field */
.passwordValidate {
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding-right: 40px; /* Ensure space for the eye icon button */
}

/* Style for the toggle button container */
.passwordField {
  position: absolute;
  top: 21px;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
.passwordInput :global(.form-control):invalid {
  background-position: right calc(0.375em + 1.5rem) center !important;
}
/* :global(.was-validated) :local(.passwordInput):invalid{
  background-position: right calc(0.375em + 0.1875rem) center;
} */

.formCard {
  /* max-width: 500px !important; */
  width: 500px;
}
@media screen and (max-width: 636px) {
  .formCard {
    min-width: 300px !important;
  }
}

@media screen and (max-width: 500px) {
  .formCard {
    min-width: 300px !important;
    width: 300px;
  }
}
