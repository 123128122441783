.cartItemListContainer {
  height: calc(100vh - 235px);
  width: 70%;
  padding: 0 0.5rem;
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.searchbarDiv {
  width: 100%;
  height: 50px;
  border: 1px solid #dedfe4;
  box-sizing: border-box;
  border-radius: 6px;
  margin: 10px auto;
  /* padding: 15px; */
  text-align: center;
}

.itemListContainer {
  width: 100%;
  height: calc(100vh - 300px);
  border: 1px solid #dedfe4;
  box-sizing: border-box;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
}
.itemListContainer table {
  font-size: 12px;
  height: calc(100vh - 307px);
  margin-bottom: 0 !important;
}
.itemListContainer thead {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.itemListContainer thead tr th:first-child {
  width: 51.8% !important;
}
.itemListContainer thead tr th:nth-child(2) {
  width: 10% !important;
}
.itemListContainer thead tr th:last-child {
  width: 8.5% !important;
}

.itemListContainer tfoot {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.itemListContainer tfoot tr th:first-child {
  width: 51.8% !important;
}
.itemListContainer tfoot tr td:first-of-type {
  width: 10% !important;
}
.itemListContainer tfoot tr td:last-child {
  width: 8.5% !important;
}

.itemListContainer tbody {
  height: calc(100vh - 356px);
  overflow: scroll;
  overflow-x: hidden;
  display: block;
  width: 100%;
}
.itemListContainer tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.itemListContainer tbody tr td {
  width: 15%;
}
.itemListContainer tbody tr td:first-child {
  width: 52% !important;
}
.itemListContainer tbody tr td:nth-child(2) {
  width: 10% !important;
}
.itemListContainer tbody tr td:last-child {
  width: 8% !important;
}

.itemListContainer tbody::-webkit-scrollbar {
  width: 0.3em;
}

.itemListContainer tbody::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.itemListContainer tbody::-webkit-scrollbar-thumb {
  background-color: rgba(178, 183, 203, 1);
  outline: 1px solid rgba(222, 223, 228, 1);
  border-radius: 6px;
}

.itemListContainer table :global(.form-control.is-invalid) {
  border-style: solid !important;
}

/* .itemListDiv {
  width: 100%;
  // max-height: calc(100vh - 365px); 
  overflow: auto;
}

.itemListDiv::-webkit-scrollbar {
  width: 0.3em;
}

.itemListDiv::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.itemListDiv::-webkit-scrollbar-thumb {
  background-color: rgba(178, 183, 203, 1);
  outline: 1px solid rgba(222, 223, 228, 1);
  border-radius: 6px;
} */

/* .itemListCountsDiv {
  width: 100%;
  height: 35px;
  border-top: 1px solid #dedfe4;
  position: absolute;
  bottom: 0;
} */

@media screen and (max-width: 767px) {
  .cartItemListContainer {
    width: 100%;
    height: 100% !important;
  }
  .itemListContainer {
    height: 100% !important;
  }
  .itemListContainer table {
    height: 100% !important;
  }
}

@media screen and (max-width: 991px) {
  .cartItemListContainer {
    height: calc(100vh - 105px);
  }
  .itemListContainer {
    height: calc(100vh - 170px);
  }

  .itemListContainer table {
    height: calc(100vh - 175px) !important;
  }

  .itemListContainer tbody {
    height: calc(100vh - 226px);
  }
}

@media screen and (max-width: 767px) {
  .cartItemListContainer {
    border-right: 0 !important;
    height: 100% !important;
  }
  .itemListContainer {
    height: 100% !important;
    overflow: auto !important;
  }

  .itemListContainer table {
    height: 100% !important;
  }

  .itemListContainer thead {
    /* height:  !important; */
    display: table-header-group;
    table-layout: auto;
  }
  .itemListContainer tfoot {
    /* height:  !important; */

    display: table-footer-group;
    table-layout: auto;
  }

  .itemListContainer tbody {
    height: 100% !important;
    display: table-row-group;
    table-layout: auto;
  }
  .itemListContainer tbody tr {
    /* height:  !important; */
    display: table-row;
    table-layout: auto;
  }
}

@media screen and (max-height: 721px) {
  .cartItemListContainer {
    height: calc(100vh - 105px);
  }
  .itemListContainer {
    height: calc(100vh - 170px);
  }

  .itemListContainer table {
    height: calc(100vh - 175px);
  }

  .itemListContainer tbody {
    height: calc(100vh - 226px);
  }
}
