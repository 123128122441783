.setcol{
    background-color: white;
}
.navtabs :global( .nav-item){
    border: 1px solid #8834ff;
}
.btn{
    border-radius: 7px 0px 0px 7px !important;
    padding: 5px 20px !important;
    background-color: white !important;
}
.btn:focus{
    background-color: #8834ff !important;
    border: 1px solid #8834ff !important;
color: #8834ff;


}
.row{
    border-radius: 7px  ;

}
.btns{
    border-radius: 0px !important;
    padding: 5px 20px !important;

}
.btns:disabled{
    color: gray !important;
}
.btns:focus{
    background-color: #8834ff !important;
    border: 1px solid #8834ff !important;
color: #8834ff;


}
.btne{
    border-radius: 0px 7px 7px 0px !important ;
    padding: 5px 20px !important;


}
.btne:disabled{
    color: gray !important;
}
.btne:focus{
    background-color: #8834ff !important;
    border: 1px solid #8834ff !important;
color: #8834ff;


}
.activebtn{
    background-color: #8834ff !important;
    color: white !important;
}

.printerSettings {
    height: 100%;
}

@media screen and (max-width: 992px) {
    .printerSettings {
        height: auto;
    }
}
@media screen and (min-width: 800px) {
    .notShow {
    display: 'none';
    }
}