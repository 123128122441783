.invoiceCard {
    max-width: 630px !important;
    width: auto;
    /* padding: 3rem; */
    color: black;
    margin: auto;
    box-shadow: 0px 20px 45px #F0EDF6;
}

.logoimg{
    width: 200px;
    max-height: 200px;
    height: 86px;
    object-fit: cover;
}

.greyscale{
    -webkit-filter: invert(100%) hue-rotate(180deg) brightness(120%) contrast(150%);
    filter: invert(100%) hue-rotate(180deg) brightness(120%) contrast(150%);
}

