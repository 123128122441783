.categoryTabsButton {
  background-color: #ffffff !important;
  color: rgba(149, 55, 255, 1) !important;
  padding: 5px !important;
  font-size: 14px;
  border: 1px solid rgba(222, 223, 228, 1) !important;
}
.categoryTabsButton:hover {
  background-color: rgba(149, 55, 255, 1) !important;
  color: #ffffff !important;
}
.categoryTabsButton.categoryTabsButtonActive {
  background-color: rgba(149, 55, 255, 1) !important;
  color: #ffffff !important;
}
.statusDropdown {
  background: transparent;
}
.du_btn:global(.btn-primary) {
  background: transparent !important;
}
.du_btn:global(.btn-primary):active {
  background: transparent !important;
}
/* .statusDropdown > button:active{
  background: transparent !important;
  color: white !important;
} */
.statusDropdown:hover {
  background-color: rgba(149, 55, 255, 1) !important;
  color: #ffffff !important;
}
.statusDropdownSpan {
  color: #39414a !important;
}
.statusDropdownSpan > button {
  border-radius: 50px;
  padding: 8px 10px !important;
}
.statusDropdown:hover :local(.spanDiv) {
  background-color: rgba(149, 55, 255, 1) !important;
  color: white !important;
}
.statusDropdown:hover :local(.du_btn) {
  background-color: rgba(149, 55, 255, 1) !important;
  color: white !important;
  transition: none !important;
}

.spanDiv {
  color: rgb(149, 55, 255) !important;
}

.spanDiv:hover {
  color: white !important;
}

.categoryTabsLinkContainer {
  border-bottom: 1px solid lightgray;
}
.categoryTabsLink {
  background: transparent !important;
  color: #39414a !important;
  padding: 5px !important;
  font-size: 14px;
  border: 0;
}
.categoryTabsLink:hover {
  border-bottom: 2px solid #8834ff !important;
}
.categoryTabsLink.categoryTabsLinkActive {
  border-bottom: 2px solid #8834ff !important;
}

.ItemsContainer {
  height: calc(100vh - 350px);
  overflow-y: auto;
}

.ItemsContainer::-webkit-scrollbar {
  width: 0.3em;
}
.ItemsContainer::-webkit-scrollbar-thumb {
  background-color: rgba(178, 183, 203, 1);
  outline: 1px solid rgba(222, 223, 228, 1);
  border-radius: 6px;
}
.ItemsContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.categoryTabsLinkContainer::-webkit-scrollbar {
  /* width: 0.3em; */
  height: 0.3em;
}
.categoryTabsLinkContainer::-webkit-scrollbar-thumb {
  background-color: rgba(178, 183, 203, 1);
  outline: 1px solid rgba(222, 223, 228, 1);
  border-radius: 6px;
}
.categoryTabsLinkContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.TilesCatBtn::-webkit-scrollbar {
  /* width: 0.3em; */
  height: 0.3em;
}
.TilesCatBtn::-webkit-scrollbar-thumb {
  background-color: rgba(178, 183, 203, 1);
  outline: 1px solid rgba(222, 223, 228, 1);
  border-radius: 6px;
}
.TilesCatBtn::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
@media screen and (max-width: 575px) {
  .ItemsContainer {
    height: 100% !important;
  }
}
@media screen and (max-width: 767px) {
  .ItemsContainer {
    height: calc(100vh - 420px);
  }
  .statusDropdown {
    border: none !important;
    margin-left: 0px !important;
    padding: 0px !important;
    background: transparent !important;
  }

  .TilesCatBtn {
    width: 78%;
    overflow-x: scroll;
  }
  .categoryTabsLinkContainer {
    overflow-x: scroll;
    overflow-y: unset;
  }
  .statusDropdownSpan {
    height: 20px !important;
  }
}
