.searchBar :global(.react-select__control--is-focused) {
  border-color: #8834ff !important;
  box-shadow: none !important;
  padding-right: 10px;
}

.searchBar input:focus {
  border: none !important;
}

.searchBar :global(.react-select__option) {
  padding-top: 3px;
  padding-bottom: 3px;
  border-bottom: 1px solid var(--bs-gray-200);
}

.searchBar :global(.react-select__menu-list)::-webkit-scrollbar {
  width: 0.3em !important;
}
.searchBar :global(.react-select__menu-list)::-webkit-scrollbar-thumb {
  background-color: rgba(178, 183, 203, 1) !important;
  outline: 1px solid rgba(222, 223, 228, 1) !important;
  border-radius: 6px !important;
}
.searchBar :global(.react-select__menu-list)::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}
