.imageInput {
    display: block;
    width: 100%;
    border: 1px dashed #DEDFE4;
    box-sizing: border-box;
    border-radius: 6px;
    margin: 5px 0px;
    padding-left: 15px;
}

.imageInput .img {
    display: flex;
    justify-content: center;
    padding: 15px;
}