.customizeTableDiv :global(.table-responsive) {
  -webkit-overflow-scrolling: unset !important;
}

.table_main tbody > tr:nth-of-type(odd) > * {
  background: rgb(248, 246, 255) !important;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 15px !important;
}

.table_main tr > td:first-child > div > div {
  display: inline-block;
  white-space: normal;
  width: 300px;
}

.table_main tr > td:first-child span {
  display: inline-block;
  white-space: normal;
  width: 300px;
}
tr.first_column_width > td:first-child > div > div {
  width: 100% !important;
}
tr.first_column_width > td:first-child span {
  width: 100% !important;
}

/* Fixed Column*/

.fixedColumnTable tr > td:first-child > div > div {
  display: inline-block;
  white-space: normal;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis !important;
  margin-bottom: 0 !important;
}

.fixedColumnTable tr > td:first-child span {
  display: inline-block;
  white-space: normal;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis !important;
  margin-bottom: 0 !important;
}

.fixedColumnTable tr > th:first-child,
.fixedColumnTable tr > td:first-child {
  /* display: block; */
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  width: 100%;
}

.fixedColumnTable tr > th:first-child::after,
.fixedColumnTable tr > td:first-child::after {
  content: "";
  position: absolute;
  /* left: 0; */
  /* left: 5px; */
  right: 0;
  display: inline-block;
  top: 0px;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.fixedColumnTable tr > th:first-child {
  background: #ffffff;
}

/*END fixed Column*/

.highlight > td:first-child {
  box-shadow: inset 3px 0 0 0 #d957f9 !important;
}

.settingsDiv {
  position: relative;
  bottom: 3px;
  right: 3px;
}
.settingsDiv:has(> :global(.driver-active-element)) {
  overflow: initial !important;
}
.settingsBtn {
  position: absolute;
  margin-top: -43px;
}
.settingsBtn img {
  width: 25px;
  height: 25px;
}

@media only screen and (max-device-width: 820px) {
  .table_main tr > th:first-child,
  .table_main tr > td:first-child {
    /* display: block; */
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    width: 100%;
    /* z-index: 1; */
  }

  .table_main tr > th:first-child::after,
  .table_main tr > td:first-child::after {
    content: "";
    position: absolute;
    right: 0;
    display: inline-block;
    top: 0px;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  .table_main tr > th:first-child {
    background: #ffffff;
  }

  .table_main_caret tr > th:nth-child(1),
  .table_main_caret tr > td:nth-child(1) {
    position: sticky;
    position: -webkit-sticky;

    left: 0;
    width: 18px;
  }
  .table_main_caret tr > th:nth-child(2),
  .table_main_caret tr > td:nth-child(2) {
    position: sticky;
    position: -webkit-sticky;

    left: 26px;
    width: 100%;
  }

  .table_main_caret tr > th:nth-child(2)::after,
  .table_main_caret tr > td:nth-child(2)::after {
    content: "";
    position: absolute;
    right: 0;
    display: inline-block;
    top: 0px;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
    z-index: 1;
  }
  .table_main_caret tr > th:nth-child(1)::before,
  .table_main_caret tr > td:nth-child(1)::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0px;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  .table_main_caret tr > th:nth-child(1) {
    background: #ffffff;
    z-index: 1;
  }
  .table_main_caret tr > td:nth-child(1) {
    z-index: 1;
  }
  .table_main_caret tr > th:nth-child(2) {
    background: #ffffff;
  }
}

@media only screen and (max-device-width: 530px) {
  .table_main tr > td:first-child > div > div {
    display: inline-block;
    white-space: normal;
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis !important;
    margin-bottom: 0 !important;
  }
  .table_main tr > td:first-child span {
    display: inline-block;
    white-space: normal;
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis !important;
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 500px) {
  .settingsDiv {
    position: relative;
    bottom: 0px;
    right: 0px;
  }
  .settingsBtn {
    position: absolute;
    margin-top: -43px;
    font-size: 0px;
  }
  .settingsBtn img {
    width: 18px;
    height: 18px;
  }
}
.disable_td {
  opacity: 0.7;
  pointer-events: none;
}
