nav {
  z-index: 1;
}

.warningBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(149, 55, 255, 0.15);
  border: 3px dashed rgba(149, 55, 255, 0.3);
  border-radius: 6px;
}
.navItem {
  display: flex;
  align-items: center;
  left: 0;
  width: 17%;
}
.navItemClose {
  display: flex;
  align-items: center;
  left: 0;
  width: 7%;
}
.userInfoPart {
  right: 0;
  width: 82%;
}
.userInfoPartClose {
  right: 0;
  width: 92%;
}
.clickableIcon {
  width: 75px;
  padding: 25px;
}
.nextToSandwich {
  width: calc(100% - 25px);
}
.cartIcon {
  text-decoration: none;
  padding: 10px;
}
.badge {
  background: #d957f9;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  padding: 1px 4px;
  color: #fff;
  position: relative;
  bottom: 8px;
  right: 5px;
  font-size: 12px;
}
.toggle :global(.btn:focus) {
  background: transparent !important;
}
.heading {
  padding: 0px 11px;
  color: var(--ds-text-subtle, #5e6c84);
  font-size: 12px;
  font-weight: 700;
  line-height: 1.45455;
  text-transform: uppercase;
}
.account_detail {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  min-height: var(--ds-scale-500, 40px);
  margin: var(--ds-scale-0, 0px);
  padding: var(--ds-scale-100, 8px) var(--ds-scale-250, 9px);
  -webkit-box-align: center;
  align-items: center;
  border: 0px;
  font-size: 14px;
  outline: 0px;
  text-decoration: none;
  user-select: none;
  background-color: transparent;
  color: currentcolor;
  cursor: pointer;
}
.email {
  color: #707070 !important;
}
.divider {
  color: #707070;
}
.tooltipSuces {
  background-color: #5784f5 !important;
}
.active {
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
  border: 1px white;
  position: absolute;
  margin-bottom: 24px;
  margin-left: 27px;
  border: 1px solid;
  box-shadow: transparent 0px 0px 0px 2px;
}
.name {
  width: 36px;
  height: 36px;
  color: #fff;
  background: linear-gradient(180deg, #a93bff 0%, #8834ff 100%);
  text-transform: uppercase;
}
.visiteicon {
  display: inline-block;
  flex-shrink: 0;
  line-height: 1;
  width: 16px;
  height: 16px;
  font-size: 14px;
}

.hamburgerWithLogo {
  display: none !important;
}

.hamburgerWithoutLogo {
  display: flex !important;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.logoClose {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.logofull {
  display: inline;
}
.logofullHide {
  display: none !important;
}
.logoBorder {
  border-right: 1px solid #f2f4fa;
}
.notificationBadge {
  background: #d957f9;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  position: absolute;
  bottom: -6px;
  right: -6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notificationCount {
  font-size: 11px;
  color: #fff;
}

@media screen and (min-width: 1800px) {
  .navItem {
    width: 15%;
  }
  .navItemClose {
    width: 5%;
  }
  .userInfoPart {
    right: 0;
    width: 84%;
  }
  .userInfoPartClose {
    right: 0;
    width: 94%;
  }
}
@media screen and (min-width: 1440px) and (max-width: 1800px) {
  .navItem {
    width: 17%;
  }
  .navItemClose {
    width: 5%;
  }
  .userInfoPart {
    right: 0;
    width: 83%;
  }
  .userInfoPartClose {
    right: 0;
    width: 94%;
  }
 
 
}

@media screen and (min-width: 1083px) and (max-width: 1400px) {
  .navItem {
    width: 20%;
  }
  .userInfoPart {
    right: 0;
    width: 79%;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .logoClose {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

 
}

@media screen and (min-width: 821px) and (max-width: 1082px) {
  .navItem {
    width: 25%;
  }
  .navItemClose {
    width: 10%;
  }
  .userInfoPart {
    right: 0;
    width: 74%;
  }
  .userInfoPartClose {
    right: 0;
    width: 89%;
  }
}

/* Tab and Mobile */
@media screen and (max-width: 820px) {
  .hamburgerWithLogo {
    display: flex !important;
    border-right: 1px solid #f2f4fa;
  }
  .hamburgerWithoutLogo {
    display: none !important;
  }
  .logofullHide {
    display: inline !important;
  }
  .logofull {
    display: inline !important;
  }
  .navItem {
    width: 40%;
  }
  .logoBorder {
    border-right: none !important;
  }
  .logo {
    width: 100%;
    justify-content: start;
    margin-left: 25px;
  }
  .logoClose {
    width: 100%;
    justify-content: start;
    margin-left: 25px;
  }
  .userInfoPart {
    width: 50%;
  }
  /* .notificationBadge {
    background: #d957f9;
    border-radius: 50%;
    height: 17px;
    width: 40px;
    padding: 1px 4px;
    color: #fff;
    position: relative;
    bottom: -7px;
    right: 4px;
    font-size: 12px;
  } */
  .notificationBadge {
    background: #d957f9;
    border-radius: 50%;
    height: 17px;
    width: 17px;
    position: absolute;
    bottom: -4px;
    right: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .notificationCount {
    font-size: 10px;
    color: #fff;
  }

  /* .warningBanner {
    display: none !important;
  } */
}
/* Only Mobile */
@media screen and (max-width: 426px) {
  .logofullHide {
    display: none !important;
  }
  .logofull {
    display: none !important;
  }
  /* .warningBanner {
    display: none !important;
  } */
}
