.vendorInfo {
    margin: 0.5rem 3rem !important;
    padding: 3rem !important;
}

.orderNum {
    padding: 0rem 3rem!important;
}

.toggle :global(.btn:focus) {
    background: transparent !important;
}
.toggle {
    background: transparent !important;
}

@media screen and (max-width: 768px) {
    .vendorInfo {
        margin: 0.5rem 0.3rem !important;
        padding: 1rem 0.5rem !important;
    }
    .orderNum {
        padding: 0rem 1rem !important;
    }
}