

  .addButtonLink{
    min-width: 140px;
  }

  .filtersContainer{
    background-color: white;
    border-bottom: 1px solid #DEDFE4;
  }

  .collapseDiv{
    border:  1px solid #DEE2E6 !important;

}
.collapseButton{
  color: #000 !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 18px !important; /* 128.571% */
  background: #FFF !important;
  border-radius: 0 !important;
  text-align: start !important;
  height: 50px !important;

}
.collapseButton:active{
  background: #ffffffd9 !important;
}

@media screen and (max-width: 1200px) {

    .filtersContainer{
        background-color: transparent;
        border: 0;
      }

    
}

@media screen and (max-width: 575px) {
  .addButtonLink{
    min-width: 0px;
  }
}