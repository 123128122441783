.card{
    box-shadow: 0px 20px 45px #F0EDF6;
}

.title{
background: #100146;
color: white;
padding-left: 2.5rem;
}
.w_30{
width: 40%;
}