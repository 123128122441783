.minWidth {
  /* min-width: 100%; */
  display: flex;
  justify-content: center;
  /* max-width: 10rem !important; */

  padding: 0;
}
:local(.image) {
  margin: 0.5rem 0.5rem;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  width: 10rem;
  height: 10rem;
  border-radius: 5px;

  border: 0.5px solid rgba(0, 0, 0, 0.12);
}
:local(.givenStyles) {
  width: 5rem;
  height: 5rem;
}
:local(.image) :global(.icon) {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background-color: lightcoral;
  color: white;
  height: 20px;
  width: 20px;
}

:local(.image) :global(.tag) {
  position: absolute;
  top: -0.7rem;
  left: 5px;
  background: rgb(67, 42, 211);
  border-radius: 0 0 50% 50%;
  color: rgb(255, 255, 255);
  height: 20px;
  padding: 0 18px 0 15px;
  text-decoration: none;
}

:local(.image) :global(.img) {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
:local(.image) :global(.icon):hover {
  background-color: red;
}

:local(.image) :global(img) {
  border-radius: 2px;
  margin: 0;
}

.ldsEllipsis,
.ldsEllipsis div {
  box-sizing: border-box;
}
.ldsEllipsis {
  display: inline-block;
  position: relative;
  width: 70px;
  height: 20px;
}
.ldsEllipsis div {
  position: absolute;
  top: 10px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.ldsEllipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.ldsEllipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.ldsEllipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.ldsEllipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
