.sidebar {
    width: 100%;
    min-height: 100vh;
}
.prodDiv{
    max-height: 187px;
    overflow-y: auto;
    overflow-x: hidden;
}
.EditprodDiv{
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
}
.userInfo {
    background: #100146;
    color: #fff;
    display: flex;
    gap: 5px;
}

.userInfo span {
    font-size: 12px;
}

.userInfo span,
p {
    letter-spacing: 0.1rem;
}

.sideLink {
    color: #774CED;
    padding: 15px;
    cursor: pointer;
}

.bannerText {
    color: rgba(248, 94, 64, 1);
}

.sideLink:hover {
    background: #F1F4FA;
    color: #774CED;
}

.active {
    background: #F1F4FA;
    color: #774CED;
}
.navTabs :global(.nav-link) {
    background: transparent !important;
    color: #39414A !important;
}
.activeLink {
    color: #774CED;
}

.sideLink a {
    color: #39414A;
    text-decoration: none;
    font-weight: bold;
}
.disableBg :global(.btn:focus){
    background: transparent !important;
}

.sideLink a:hover {
    text-decoration: none;
    color: #774CED;
}

.dashboardLayout {
    min-height: 100vh;
}

.sidebarLayout {
    margin-top: 65px !important;
    padding: 0px !important;
    width: 13.2%;
}

.sidebarItems {
    min-height: 80vh;
}

.content {
    position: absolute;
    width: 86.3%;
    left: 13.7%;
    top: 50px;
}

.signOut {
    color: #5932EA;
    cursor: pointer;
    font-size: 1.2rem;
}

.sandwichOpen {
    width: 100%;
}

.sidebarLayoutClose {
    width: 5%;
    margin-top: 65px !important;
    padding: 0px !important;
}

.contentClose {
    width: 80%;
    left: 8%;
}

.infoDiv {
    background: #100146;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.submenu {
    text-decoration: none;
    color: #39414A;
}

.submenuItem {
    font-size: 15px;
    padding: 8px;
    border-radius: 20px;
    margin-right: 12px;
}

.submenuItem:hover {
    background: #F1F4FA;
    color: #5932EA;
}

.toggle :global(.btn:focus) {
    background: transparent !important;
}
.toggle {
    background: transparent !important;
    top: 34px;
}

.navlink:hover {
    border-bottom: 1px solid #5932EA !important;
    color: #5932EA !important
}

.navlink:focus {
    color: #5932EA !important;
    border-bottom: 1px solid #5932EA !important;
}

.navlink {
    background-color: transparent !important;
    color: black !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

.chooseproducts {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #39414A;
    height: 29px;
}

.salesdate {
    color: #39414A;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px
}

.salesresults {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #686E76
}

.salesFormCard {
    min-width: 319px !important;
    width: auto;
    height: 1075px;
    padding: 1rem;
}

/*  salesCard css */
.itemsincart {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #0E1724
}

.items {
    color: #686E76;
    width: 94px;
    height: 18px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400
}

.qty {
    color: #686E76;
    width: 39px;
    height: 18px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400
}

.addmisc {
    color: #9537FF;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    cursor: pointer;
}

.sidecardimg {
    width: 42px;
    height: 42px;
}

.miscitems {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.13px;
    color: #39414A;
}

.itemPrice {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #212529;
}

.miscItemImage {
    width: 42px;
    height: 42px;
}

.cartField {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.13px;
    color: #39414A;
    opacity: 0.5;
}

.boxmiscitems {
    box-sizing: border-box;
    position: absolute;
    width: 44px;
    height: 34px;
    right: 87px;
    top: 239px;
    background: #FFFFFF;
    border: 1px solid #BEC0C2;
    border-radius: 6px;
}

.borderedHr {
    border-top: 2px dashed #DEDFE4;
    height: 0 !important;
    background: none !important;
}

.nav {
    overflow:hidden;
}

.cartButtons {
    border: 1px solid #ced4da;
}

.cartButtons:focus {
    outline: none;
    border:1px solid #ced4da ;
}

.cartItemsBox {
    max-height: 175px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.imeiBtn {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.13px;
    color: #9537FF;
    cursor: pointer;
}

.imeiNumbers {
    border-top: 2px solid;
    border-bottom: 2px solid;
    border-color: #DEE2E6;
    padding: 3px 0px;
}

.imeiNumbers img {
    pointer-events: none;
}

.imeiNumbers .grid {
    display: flex;
    gap: 5px 3px;
    align-items: center;
    flex-wrap: wrap;
}

.imeiNumbers .tag {
    padding: 3px 8px;
    border-radius: 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.13px;
    color: #39414A;
}

.showMoreText {
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.13px;
    color: #8834ff;
}

@media screen and (max-width: 768px) {
    .sidebarLayout {
        width: 100%;
    }

    .sandwichOpen {
        display: none;
    }

    .content {
        left: 7.2%;
    }

    .navlink {
        font-family: 'Noto Sans' !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        align-items: center !important;
    }

    .nav {
        display: flex !important;
        flex-wrap: nowrap !important;
        max-width: 100%;
        width: 100%;
        overflow-x: scroll;
    }

    .chooseproducts {
        display: flex;
        font-family: 'Cabin';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #39414A;
    }

    .cartCard {
        padding: 0 0.5rem !important;
    }
    .toggle {
        /* top: 34px; */
        top: 0px;
    }
}
@media screen and (max-width: 1200px) {
    .nav_tabs{
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        flex-wrap: nowrap !important;
        width: 347px; 
    }
}