.searchInput {
  /* border-radius: 50px !important; */
  padding: 0.375rem 10px 0.375rem 2.5rem !important;
  font-family: sans-serif;
}
.searchIconTradeIn {
  position: absolute;
  top: 34px;
  left: 20px;
}
.searchIcon {
  position: absolute;
  top: 7px;
  left: 3px;
}
.search {
  padding-left: 2rem !important;
}
.iconBorder {
  padding: 6px;
  border: 1px solid;
  border-radius: 6px;
}
.searchIcon_Mobile {
  color: #9537ff;
  position: absolute;
  top: -20px !important;
  padding-bottom: 0.4rem !important;
}

@media screen and (max-width: 900px) {
  .searchIcon {
    color: #9537ff;
    position: absolute;
    left: 1;
  }
  .searchInput {
    border-radius: 50px !important;
    padding: 0.375rem 2.5rem !important;
  }
  .coloredInput {
    border: 1px solid #9537ff !important;
  }
  .coloredInput::placeholder {
    color: #9537ff !important;
  }
}

@media screen and (max-width: 575px) {
  .searchIcon_Mobile {
    width: 44px;
    height: 40px;
  }
}
