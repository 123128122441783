.globalLocation {
  width: 100%;
  margin-right: 30px;
}
.globalLocation :global(.react-select-container__value-container) {
  width: 200px;
  text-wrap: nowrap;
}
.globalLocation button {
  width: 100% !important;
}

.registerButton:disabled {
  border-color: transparent !important;
  background-color: #6c757d !important;
  color: white !important;
}

@media screen and (max-width: 992px) {
  .globalLocation {
    width: 100%;
  }
  .globalLocation :global(.react-select-container__value-container) {
    height: 36px;
    text-wrap: nowrap;
  }
  .globalLocation button {
    height: 41px;
    width: 120px;
    text-wrap: nowrap;
    overflow: hidden;
  }
}

@media screen and (max-width: 575px) {
  .globalLocation {
    width: 100%;
  }
  .globalLocation :global(.react-select-container__value-container) {
    height: 36px;
    width: 200px;
    text-wrap: nowrap;
    /* margin-left: 14px; */
  }
  /* .globalLocation :global(.react-select-container__control) {
    margin-left: 14px;
    width: 100% !important;
  } */
  .globalLocation button {
    height: 41px;
    width: 100%;
  }
}
