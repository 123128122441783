.borderedHr {
    border-top: 2px dashed #DEDFE4;
    height: 0 !important;
    background: none !important;
}
.borderedHr2 {
    border-top: 2px dashed #c5c7d1;
    height: 0 !important;
    background: none !important;
}

.formMain {
    padding: 0 3rem !important;
}
.selectCAt :global(.react-select-container__menu-list) {
    /* position: absolute; */
    bottom: 0;
}
/* .validationStyle{

    border: 1px solid red !important; 
    background-color: yellow;


} */

.upload_btn_wrapper{
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
}
.upload_btn_wrapper input[type=file] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  }
@media screen and (max-width: 768px) {
    .formMain {
        padding: 0 1rem !important;
    }
}