.background {
    opacity: 0.1;
    filter: blur(10px);
}

.formContent {
    z-index: 1;
}

.addForm {
    padding: 0 50px !important;
}
.form {
    width: 50% !important;
    box-shadow: 0px 20px 45px #f0edf6 !important;
}
@media screen and (max-width: 1500px) {
    .addForm {
        padding: 0 10px !important;
    }
    .form {
        width: 80% !important;
    }
 }

@media screen and (max-width: 768px) {
    .addForm {
        padding: 0 10px !important;
    }
    .form {
        width: 100% !important;
    }
 }

 @media screen and (max-width: 1024px) {
    .addForm {
        padding: 0 10px !important;
    }
    .form {
        width: 100% !important;
    }
 }