.inventorybtn {


    background: #FFFFFF;
    color: #9537FF;
    border-bottom: 1px solid #979CA2;
    border-top-left-radius: 0.4rem;
    border-left: 1px solid #979CA2;
    border-top: 1px solid #979CA2;
    border-right: 1px solid #979CA2
}

.archivebtn {

    background: #FFFFFF;
    color: #9537FF;
    border-bottom: 1px solid #979CA2;
    border-top-right-radius: 0.4rem;
    border-left: 0px solid #979CA2;
    border-right: 1px solid #979CA2;
    border-top: 1px solid #979CA2;
}

.inventorybtn:focus {

    background-color: #9537FF;
    color: #FFFFFF
}

.navtabs :global(.nav-link) {
    background-color: #FFFFFF !important;
    color: rgba(149, 55, 255, 1) !important;
    padding: 5px !important;
    font-size: 14px;
    border: 1px solid rgba(222, 223, 228, 1) !important;
}

.navtabs :global(.nav-link.active) {
    background-color: rgba(149, 55, 255, 1) !important;
    color: #FFFFFF !important;
}

.archivebtn:focus {

    background-color: #9537FF;
    color: #FFFFFF
}

.condition .react-select-3-placeholder {
    font-size: 10px !important;
 
}
.dangersign{

    position:absolute;
     bottom: 9.9rem;

}
.btnwidth{

    height: 40px;
    width: 100%;

}

@media screen and (max-width: 768px) {
    .dangersign{

        position:absolute;
         bottom: 11.4rem;
    
    }
}
@media screen and (max-width: 576px) {
    .btnstyle{

        height: 40px;
        width: 44px;
    
    }
}
@media screen and (max-width: 500px) {
    .btnwidth{

        height: 40px;
        width: 45px;
    
    }
}