.tabledescription {
  font-weight: 500;
  font-size: 13px;
  color: #100146;
  width: 48%;
  overflow-wrap: anywhere;
}

.tabledescription span {
  font-size: 11px;
}

.tabletxt {
  font-weight: 500;
  font-size: 13px;
  color: #39414a;
}

.address {
  margin-bottom: 3rem;
}

.invoice {
  margin-bottom: 3rem;
}

.invoiceterms {
  color: #686e76;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 6px;
}

.terms {
  font-size: 11px;
  font-style: italic;
}

.buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.printbtn {
  width: 92px;
}

.emailbtn {
  width: 115px;
}

.emailphone {
  color: #39414a;
  margin-bottom: 0rem;
  font-size: 14px;
}

.invoicetxt {
  color: #39414a;
  margin-bottom: 0rem;
  font-size: 16px;
  font-weight: 600;
}

.itemsTable {
  border: 1px solid #000;
  width: 90%;
}

.itemsTable span {
  font-size: 10px;
}

.invoiceTable th {
  padding: 1rem 0.3rem;
}
.invoiceTable td {
  padding: 0.3rem 0.3rem;
  border-bottom: 1px solid rgba(222, 223, 228, 1);
}

.invoiceTablePayments th {
  padding: 0.8rem 0.2rem;
}
.invoiceTablePayments td {
  padding: 0.3rem 0.3rem;
  border-bottom: 1px solid rgba(222, 223, 228, 1);
}

@media screen and (max-width: 768px) {
  .addresstxt {
    font-size: 12px;
    font-weight: 400;
  }

  .logoadd {
    margin-top: 1rem;
  }

  .invoice {
    margin-bottom: 1rem;
  }

  .emailphone {
    color: #39414a;
    margin-bottom: 0rem;
    font-size: 12px;
  }

  .address {
    margin-bottom: 1rem;
  }

  .invoiceterms {
    color: #686e76;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 6px;
  }

  .invoicenum {
    flex: none !important;
    display: flex;
    gap: 1rem;
  }

  .invoicetxt {
    color: #39414a;
    margin-bottom: 1rem;
    font-size: 13px;
    font-weight: bolder;
  }
}

@media screen and (min-width: 500px) {
  .invoicenum {
    display: block;
  }
  .phn {
    display: block;
    gap: 0.08rem;
  }
  .emailphone {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 500px) {
  .invoicenum {
    flex: none !important;
    display: flex;
    gap: 1rem;
  }
  .phn {
    display: block;
    gap: 0.08rem;
  }
  .logo {
    width: 110px;
    height: 75px;
  }
  .tabledescription {
    font-size: 8px;
    padding: 5px !important;
  }
  .tabletxt {
    font-size: 7px !important;
    padding: 5px !important;
  }
}

@media screen and (max-width: 767px) {
  .invoicenum {
    padding-left: 1rem !important;
  }
}

@media screen and (max-width: 600px) {
  .tabledescription {
    font-size: 10px;
  }
  .addresstxt {
    font-size: 12px;
  }
  .invoice {
    font-size: 18px;
  }
  .terms {
    font-size: 10px;
  }
  .invoicetxt {
    font-size: 12px;
  }
}
