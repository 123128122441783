.isChecked {
    pointer-events: none;
}

.loading {
    height: 20vh;
}

.title {
    word-wrap: break-word;
}

.title span {
    max-width: 100%;
    overflow-wrap: break-word;
}

.refundModal :global(.modal-body) {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
}

.refundItem {
    border: 1px solid #DEDFE4;
    padding: 10px 0px;
}

.refundRow {
    max-width: 100%;
    margin: auto !important;
}

.refundItemData, .refundItemElements {
    border-bottom: 1px solid #DEDFE4;
    padding: 10px 0px;
}

.refundItemheader span {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #6C757D;
}

.item {
    padding-right: 10px !important;
}

.item2 {
    padding-right: 50px !important;
}

.lastItem {
    padding-right: 35px !important;
}

.checkedItem {
    background: #F8F6FF !important;
}

.totals {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    text-align: right;
    color: #100146;
}

.totals strong {
    font-weight: 700;
}

.paymentType {
    width: 22%;
    margin: 10px 0px;
}

:local(.bankDropdown) :global(.react-select-container__menu){
    height: 130px;
} 
:local(.bankDropdown) :global(.react-select-container__menu-list){
    height: 130px;
} 

@media screen and (max-width: 992px) {
    .item {
        padding-right: 5px !important;
    }
    
    .item2 {
        padding-right: 10px !important;
    }
    
    .lastItem {
        padding-right: 10px !important;
    }

    .paymentType {
        width: 50%;
    }
}

@media screen and (max-width: 575px) {
    .paymentType {
        width: 62%;
    }
}