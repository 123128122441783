.modalDialog :global(.table) {
  margin-bottom: 0 !important;
}

/* .modalDialog :global(.modal-content){
    max-height: calc(100vh - 100px) !important;
} */

.tableContainer {
  max-height: calc(100vh - 320px) !important;
  /* max-height: calc(100vh - 220px) !important; */
  /* min-height: 100%; */

  width: 100%;
  /* height: 85% !important; */
}

.tableContainer::-webkit-scrollbar {
  width: 0.3em;
}
.tableContainer::-webkit-scrollbar-thumb {
  background-color: rgba(178, 183, 203, 1);
  outline: 1px solid rgba(222, 223, 228, 1);
  border-radius: 6px;
}
.tableContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.toggleButtonContainer {
  display: inline-flex;
  border-radius: 26px;
  border: 1px solid #9537ff;
  background: #fff;
  padding: 5px;
  gap: 5px;
}
.toggleButtonContainer button {
  background-color: white;
  outline: none;
  border: 0;
  border-radius: 26px;
  padding: 5px 20px;

  color: #100146;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.toggleButtonContainer button.active {
  background-color: #9537ff !important;
  color: #fff;
  font-weight: 600;
}
.toggleButtonContainer button:hover {
  background-color: #f5f6fa;
}

.progressBarContainer {
  height: 20px;
  border-radius: 20px;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.progressBar {
  border-radius: 20px;
  height: 18px;
  margin: 1px;
  transition-duration: 1s;
  background-color: #a93bff !important;
}

.progress100 {
  background-color: #18a538 !important;
  transition-duration: background-color 4s;
}

.customizeTitleField {
  display: flex !important;
}
.customizeTitleField:has(.customizeTitleFieldInput:focus, .customizeTitleFieldInput:focus-visible) {
  box-shadow: none !important;
  border: 1px solid #d957f9 !important;
}

.disabledDiv {
  background-color: var(--bs-secondary-bg) !important;
  opacity: 1;
}
.customizeTitleFieldSpan {
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
}
.customizeTitleFieldInput {
  border: 0;
  width: 100%;
}
.customizeTitleFieldInput:focus-visible {
  outline: none;
}
.customizeTitleFieldInput:focus {
  border: 0 !important;
}

@media screen and (max-width: 575px) {
  .modalDialog :global(.modal-content) {
    height: 100vh !important;
  }
  .tableContainer {
    max-height: calc(100% - 65px) !important;
    height: 100% !important;
  }
}
@media screen and (max-width: 500px) {
  .modalDialog :global(.modal-body) {
    padding: 0 !important;
  }
}
