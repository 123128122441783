.tabButton{
    height: 37px;
padding: 5px 14px;
cursor: pointer;
}

.activeTabButton{
    color: #FFF;
text-align: center;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 160% */
border-radius: 6px 6px 0px 0px;
background: #8834FF;
}

.inactiveTabButton{
    color: #8834FF;
text-align: center;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 160% */
    border-radius: 6px 6px 0px 0px;
border: 1px solid #DEDFE4;
background: #FFF;
}

.listingContainer{
    min-height: calc(100vh - 137px);
}

@media screen and (max-width: 575px) {
    
}