.boldTxt {
  font-weight: 700;
  font-size: 30px;
  color: #39414a;
}

.accordian {
  background: #ffffff;
  margin-right: 0px !important;
  margin-left: 0px !important;
  margin-bottom: -3px;
  border: 1px solid #dedfe4;
}
.totalAccordian {
  background: #ffffff;
  margin-right: 0px !important;
  margin-left: 0px !important;
  margin-bottom: 2rem !important;
  border: 1px solid #dedfe4;
}
.noDataAccordian {
  background: #ffffff;
  margin-right: 0px !important;
  margin-left: 0px !important;
  margin-bottom: -3px;
  border-radius: 5px 5px 5px 5px;
  border: 1px solid #dedfe4;
}
.brdrd :global(.table tr > td:first-child) {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  color: #100146 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}
.brdrd :global(.table tr > th:first-child) {
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  color: #100146 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}
.brdrd :global(table) {
  border-radius: 0px !important;
  border-color: #dedfe4 !important;
  border: 1px solid #dedfe4;
  border-top: 0px !important;
}
.brdrd :global(.table) {
  margin-bottom: 0px !important;
}
.table {
  margin-top: 0px !important;
}

.salespadding {
  padding: 0.7rem !important;
}

.focus :global(.btn:focus) {
  background: none !important;
}
.cardstxt {
  height: 21px;

  font-weight: 600;
  font-size: 14px;

  color: #39414a;
}

.noData {
  border-top: 5px solid #dee2e6;
  border-bottom: 5px solid #dee2e6 !important;
}

.cardstitle {
  font-weight: 600;

  color: #100146;
  white-space: pre;
  font-size: 22px !important;
  line-height: 32px;
  letter-spacing: -0.01rem;
}

.cards {
  /* width: 20rem; */

  background: #ffffff;
  box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
}

.soldproducts {
  width: 229px;
  height: 33px;

  font-weight: 600;
  font-size: 22px;

  color: #39414a;
}

.dateprsold {
  color: #39414a;
}

.prsoldcard {
  /* width: 964px; */
  height: auto !important;

  background: #ffffff;
  box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
}

.overflowcard {
  /* width: 964px; */
  height: 100% !important;

  background: #ffffff;
  box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
}

.chartcarddate {
  width: 14% !important;
}

.productOverflow {
  max-height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll::-webkit-scrollbar {
  width: 0.3em;
}

.scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scroll::-webkit-scrollbar-thumb {
  background-color: rgba(178, 183, 203, 1);
  outline: 1px solid rgba(222, 223, 228, 1);
  border-radius: 6px;
}

.dateFilter {
  flex-direction: row !important;
  width: 50% !important;
}

.dateFilter label {
  width: 75%;
  text-align: end;
}
.datpicker {
  border: 1px solid #ced4da;
  height: 2.7rem;
  border-radius: 5px;
  padding-left: 1rem;
  padding-top: 0px;
  position: relative;
  width: 100%;
}
.notShow {
  display: flex;
}
.show {
  display: flex;
}
.notShowMobile {
  display: flex;
}
.showMobile {
  display: flex;
}
.gradientLink {
  color: #a93bff;
}
.LegendsLinePsold {
  border-radius: 20px;
  background-color: #100146;
  opacity: 1;
}
.LegendsLineTsales {
  border-radius: 20px;
  background-color: #af45d4;
  opacity: 1;
}
.LegendsLineNoSales {
  border-radius: 20px;
  background-color: #5932ea;
  opacity: 1;
}
.LegendsLineDiscount {
  border-radius: 20px;
  background-color: #e166ff;
  opacity: 1;
}
.filterBtn {
  bottom: 9px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 90%;
  left: 18px;
}

.reportsNavButtons > button {
  border-right: 1px solid #8834ff !important;
}

.reportsNavButtons > button {
  border-top: 1px solid #8834ff !important;
  border-bottom: 1px solid #8834ff !important;
}

.reportsNavButtons > button:first-child {
  border-left: 1px solid #8834ff !important;
  border-top-left-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.reportsNavButtons > button:last-child {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.item {
  flex: 1;
  min-width: calc(25% - 20px); /* Adjust spacing as needed */
  padding: 10px;
  box-sizing: border-box;
}

@media (max-width: 1500px) {
  .item {
    flex-basis: calc(50% - 20px); /* Adjust spacing as needed */
  }
}

@media (max-width: 1000px) {
  .item {
    flex-basis: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .chartcarddate {
    width: 50% !important;
  }

  .dateFilter {
    flex-direction: column !important;
    width: 100% !important;
  }
  .dateFilter label {
    width: 100%;
    text-align: start;
    margin-left: 20px;
    margin-bottom: 10px;
  }

  .overflowcard {
    /* width: 964px; */
    height: 100% !important;

    background: #ffffff;
    box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
  }
}
@media screen and (max-width: 575px) {
  .datePicker {
    margin-bottom: 7px;
  }
}
@media screen and (min-width: 850px) {
  .show {
    display: none;
  }
}
@media screen and (max-width: 850px) {
  .notShow {
    display: none;
  }
}
@media screen and (min-width: 500px) {
  .showMobile {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .notShowMobile {
    display: none;
  }
}
