.pageTitle {
  color: #39414a;
  font-family: Cabin;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px; /* 165% */
}

.searchInputControl {
  padding: 0.375rem 0 0.375rem 2.5rem !important;
  font-family: sans-serif;
  width: 280px;
}

.searchIcon {
  position: absolute;
  top: 7px;
  left: 7px;
}
.ShowingSelect {
  width: 200px;
  border-radius: 6px;
}
.iconBorder {
  padding: 6px;
  border: 1px solid;
  border-radius: 6px;
}

.searchIconMobile {
  color: #9537ff;
}

.addButtonLink {
  min-width: 140px;
}

.filtersContainer {
  background-color: white;
}

.filtersContainerBottom {
  border-bottom: 1px solid #dedfe4;
}

@media screen and (max-width: 1200px) {
  .pageTitle {
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
  }
  .filtersContainer {
    background-color: transparent;
  }

  .filtersContainerBottom {
    border: 0;
  }
}

@media screen and (max-width: 575px) {
  .addButtonLink {
    min-width: 0px;
  }
}

:local(.categoryFilter) :global(.react-select-container__menu) {
  height: 140px;
}
:local(.categoryFilter) :global(.react-select-container__menu-list) {
  height: 140px;
}
:local(.categoryFilterLg) :global(.react-select-container__menu) {
  height: 190px;
}
:local(.categoryFilterLg) :global(.react-select-container__menu-list) {
  height: 190px;
}
