.tagArea {
    padding: 0.5rem;
    outline: none;
    width: 420px;
    border: 1px solid #BEC0C2;
    border-radius: 6px;
    transition: all 0.2s;
    cursor: text;
    display: flex;
    align-items: center;
    position: relative;
}

.tagArea ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0px;
}

.tagArea li {
    list-style: none;
}

.tag {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    padding: 5px 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.13px;
    color: #39414A;
    border-radius: 100px;
    margin: 0.5rem;
}

.tagInput {
    padding: 0.5rem;
    outline: none;
    border: none;
    width: 150px;
    margin-left: 0.5rem;
    background: transparent;
    font-size:16px;
}

.tagInput:focus {
    border: none !important;
}

.tag button {
    border: none;
    outline: none;
    background-color: transparent;
}

.tag svg {
    font-size: 12px;
}
.imei :global(.modal-content){
    box-shadow: 0 0 14px black;

}
@media screen and (max-width: 575px) {
    .tagArea {
        width: 100%;
    }
}