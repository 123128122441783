.Card{
    width: 1080px;
}

.brandLogo{
    width: 200px;
    max-height: 200px;
    height: 86px;
    object-fit: contain;
}

.terms {
    font-size: 13px;
    font-style: italic;
}

@media screen AND (max-width:768px) {
    
}