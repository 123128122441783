.form-control {
  /* border-radius: 50px !important; */
  padding: 0.375rem 2.5rem !important;
  font-family: sans-serif;
}
.wrapper {
  height: 90vh;
}

.wrapper :global(.alert) {
  width: 100%;
  max-width: 400px;
  margin: 0px auto 10px;
}

.passwordInput {
  position: relative;
}

.passwordValidate {
  background-image: none !important;
}

.passwordInput svg {
  bottom: 13px;
  position: absolute;
  right: 10px;
  width: 16px;
}

.passwordField {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.formCard {
  /* max-width: 500px !important; */
  width: 500px;
  padding: 3rem;
  box-shadow: 0px 20px 45px #f0edf6 !important;
}
.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}
.passwordInput input {
  padding-right: 30px !important;
}
.passwordInput :global(.form-control):invalid {
  background-position: right calc(0.375em + 1.1875rem) center !important;
}

.backToLogin {
  text-align: center;
  font-weight: 400;
  font-size: 19.8px;
  font-family: osicons;
}
@media screen and (max-width: 636px) {
  .formCard {
    padding: 1rem;
    min-width: 300px !important;
  }
  .wrapper :global(.alert) {
    width: 100%;
    margin: 0px auto 10px;
  }
}

@media screen and (max-width: 500px) {
  .formCard {
    padding: 1rem;
    min-width: 300px !important;
    width: 300px;
  }
  .wrapper :global(.alert) {
    width: 100%;
    margin: 0px auto 10px;
  }
}
