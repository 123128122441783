.formCard {
  width: 500px;
  padding: 3rem;
  box-shadow: 0px 20px 45px #f0edf6 !important;
}
.disabledbutton {
  pointer-events: none;
  opacity: 0.4;
}
@media screen and (max-width: 636px) {
  .formCard {
    padding: 1rem;
    min-width: 300px !important;
  }
}

@media screen and (max-width: 500px) {
  .formCard {
    min-width: 300px !important;
    width: 300px;
  }
}
