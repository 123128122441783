.printComponent {
  margin: 0;
  padding: 0;
}
.printComponent2 {
  margin: 3rem;
  padding: 0;
}

.invoicePrint {
  margin: 3rem;
}

@media screen and (max-width: 800px) {
  .printComponent2 {
    margin: 2rem;
    padding: 0;
  }
  .invoicePrint {
    margin: 0rem;
    padding: 1rem !important;
  }
}

@media print {
  html,
  body {
    height: inherit;
    overflow: initial;
    page-break-inside: always;
    break-inside: auto;
  }
  table {
    page-break-after: auto;
    font-family: Arial, sans-serif !important;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
    font-family: Arial, sans-serif !important;
  }
  td {
    page-break-inside: avoid;
    page-break-after: auto;
    font-size: 10px !important;
    font-family: Arial, sans-serif !important;
  }
  th {
    page-break-inside: avoid;
    page-break-after: auto;
    font-size: 10px !important;
    font-family: Arial, sans-serif !important;
  }
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }

  .printComponent2 {
    margin: 1rem 0.5rem;
    padding: 0;
  }
  .shopInfo {
    display: block !important;
  }
  .address {
    display: block !important;
  }
  .address h5,
  span {
    font-size: 10px !important;
  }
  .terms {
    font-size: 8px !important;
  }
  .invoicePrint {
    margin: 1rem !important;
    padding: 0rem !important;
  }
  .laser-invoice {
    @page {
      size: A4 portrait;
    }
  } 
}