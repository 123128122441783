.successBg {
  background-color: #c0f1d1;
  border-radius: 6px;
}
@media screen and (max-width: 900px) {
  .mdSize :global(.modal-dialog) {
    max-width: 600px;
  }
}
@media screen and (min-width: 900px) {
  .customSize :global(.modal-dialog) {
    max-width: 960px !important;
  }
}

.errorBg {
  background-color: #fcd4cc;
  border-radius: 6px;
}

.modalIcon {
  font-size: 3rem;
}

.successIcon {
  color: #2dd267;
}

.errorIcon {
  color: #f85e40;
}

:local(.bankDropdown) :global(.react-select-container__menu) {
  height: 140px;
}
:local(.bankDropdown) :global(.react-select-container__menu-list) {
  height: 140px;
}

:local(.salesPersonDropdown) :global(.react-select-container__menu) {
  height: 110px;
}
:local(.salesPersonDropdown) :global(.react-select-container__menu-list) {
  height: 110px;
}

:local(.locationDropdown) :global(.react-select-container__menu) {
  height: 130px;
}
:local(.locationDropdown) :global(.react-select-container__menu-list) {
  height: 130px;
}

:local(.locationDropdown) :global(.react-select-container__menu-list)::-webkit-scrollbar {
  width: 0.3em;
}
:local(.locationDropdown) :global(.react-select-container__menu-list)::-webkit-scrollbar-thumb {
  background-color: rgba(178, 183, 203, 1);
  outline: 1px solid rgba(222, 223, 228, 1);
  border-radius: 6px;
}
:local(.locationDropdown) :global(.react-select-container__menu-list)::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
