.modalDialog :global(.table){
    margin-bottom: 0 !important;
}
.modalDialog :global(.modal-header) :global(.btn-close){
    margin: 0 !important;
}
.modalDialog :global(.modal-header) :global(.modal-title){
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.modalDialog :global(.modal-header) :global(.modal-title) img{
    margin-right: 30px;
    cursor: pointer;
    opacity: 0.5;
}
.modalDialog :global(.modal-header) :global(.modal-title) img:hover{
    opacity: 0.9;
}

/* .modalDialog :global(.modal-content){
    max-height: calc(100vh - 100px) !important;
} */

.tableContainer{
    max-height: calc(100vh - 220px) !important;
    /* height: 100%; */
    width: 100%;
    /* height: 85% !important; */
}



/* MULTIPART FORM PROGRESS */
.stepper_progress{
    width: 100%;
    display: flex;
    list-style-type: none;
    height: 40px;
}
.stepper_progress li{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stepper_progress li > div{
    padding: 5px 20px;
    display: flex;
    gap: 5px;
    align-items: center;

    /* background-color: lightgray;
    border-radius: 5px; */
}
.stepper_progress li > div.active > div{
    font-weight: 800;
}

/* .stepper_progress div.underline{
    text-decoration: underline;
} */

.stepper_progress li::before,
.stepper_progress li::after
{
    display: flex;
    content: "";
    background-color: lightgray;
    height: 1px;
    width: 100%;
    
}
.stepper_progress li:has(> div.active)::before,
.stepper_progress li:has(> div.active)::after
{
    background-color:     rgb(0, 0, 0);  
}

/* END OF MULTIPART FORM PROGRESS */




@media screen and (max-width: 575px) {
    .modalDialog :global(.modal-content){
        height: 100vh !important;
    }
    .tableContainer{
        max-height: 100% !important;
        height: 100% !important;
    }
  }
@media screen and (max-width: 500px) {
    .modalDialog :global(.modal-body){
        padding:0 !important;
    }
  }