/* .cartContainer{
    margin-right: -8px;
} */

.miniCartContainer {
  margin-top: 72px;
  width: 200px;
  background-color: white;
  box-shadow: 0px 10px 60px rgb(226 236 249 / 50%);
  height: calc(100vh - 200px);
  border-radius: 10px 0 0 10px;
  display: flex;
}
.miniCartBody {
  height: calc(100vh - 200px);
  overflow-y: auto;
}

.miniCartBody::-webkit-scrollbar {
  width: 0.3em;
}
.miniCartBody::-webkit-scrollbar-thumb {
  background-color: rgba(178, 183, 203, 1);
  outline: 1px solid rgba(222, 223, 228, 1);
  border-radius: 6px;
}
.miniCartBody::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.actionToOpen {
  width: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-right: 1px solid rgba(222, 223, 228, 1) !important; */
  border-radius: 10px 0 0 10px;
  border-right: 1px solid #8934ff4f !important;
  color: rgb(182, 185, 202);
  cursor: pointer;
}
.actionToOpen:hover {
  background: rgb(248, 246, 255);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 15px;
  color: #8834ff;
}

.cartItemTable {
  max-height: 150px;
  overflow-y: auto;
}

.cartItemTable::-webkit-scrollbar {
  width: 0.3em;
}
.cartItemTable::-webkit-scrollbar-thumb {
  background-color: rgba(178, 183, 203, 1);
  outline: 1px solid rgba(222, 223, 228, 1);
  border-radius: 6px;
}
.cartItemTable::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.cartItemTable tr:first-child {
  position: sticky;
  top: 0;
  background: white;
}
