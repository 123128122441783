:local(.section) {
    display: block;
    width: 100%;
    border: 1px solid #DEDFE4;
    box-sizing: border-box;
    border-radius: 6px;
    margin: 10px auto;
    padding: 15px;
    text-align: center;
}
:local(.section) :global(label){
    width: 100%;
    background-color: #dedfe47a;
    border: 1px dashed #DEDFE4;
    box-sizing: border-box;
    border-radius: 6px;
    margin: auto;
    padding: 3rem 15px;
    cursor: pointer;
    text-align: center;
    
}
:local(.section) :global(span){
    margin: 10px 0px;
    font-size: 12px;
}

.videoContainer{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.videoBox{
    position: relative;
    width: 300px;
    height: 300px;
}

.videoContainer .videoAction{
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    padding: 20px 10px 0 0;
}


.videoBox :global(.icon){
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    background-color: lightcoral;
    color: white;
    height: 20px;
    width: 20px;
    z-index: 10;
}

/*    */




.border {
    border: 1px dashed #DEDFE4;
    border-radius: 6px;
    gap: 15px
}

.browseTxt {
    color: rgba(89, 50, 234, 1);
    font-size: 14px !important;
}

.txt {
    font-size: 14px;
}

.txtSize {
    font-size: 12px;
    display: block;
}