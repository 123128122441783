.theme_item .Preview_Btn {
    display: none;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 40%;
}

.customizeModal :global(.modal-content){
    margin: 30px;
    box-shadow: rgba(100, 100, 111, 0.2) -1px 3px 25px 400px;

}

.theme_item{
    position: relative;
}

.theme_item .Preview_Btn:hover {
    display: block;
}
.applyBtnDiv{
    /* bottom: 0; */
    height: 450px;
    transform: translateY(calc(100% - 3.5rem));
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
    border-radius: 20px 20px 20px 20px;
    background: rgba(0, 0, 0, 0.5);
}

.applyBtnDiv button{
    bottom: 0;
}

:global(.slick-current):hover .applyBtnDiv{
    transform: translateY(calc(100% - 28.04rem));
    opacity: 1;
    transition: transform 0.3s ease, opacity 0.3s ease;

}


 .selectedOne{
    box-shadow: rgba(57, 246, 127, 0.2) -1px 3px 25px 7px  !important;

}
.sidebar{
    /* height: calc(100vh - 425px) !important; */
    /* height: 100vh; */
    height: calc(100vh - 400px) !important;

}
.selected{
    border: 2px solid #D957F9 !important;
    box-shadow: 0px 10px 50px 0px rgb(131 0 233 / 20%) !important;
}
.selectedNav{
    background: rgb(229, 225, 244);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 15px;
}
.selectedNav span{
    color:#774ced !important
}

.empty{
    border :2px solid tomato !important
}

.complete{
    border: 2px solid green!important
}

.sliderDiv{
    width:80% !important;
    height:450px
  }

 .formdiv{
    height: 450px !important;
    overflow: auto;
 }

.formdiv::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.formdiv::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

.formdiv::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #dad6d6;
}


@media screen and (max-width: 1500px) {
  .sliderDiv{
    width:100% !important;
    height:450px
  }
  }
  
  @media screen and (max-width: 1024px) {
    .sliderDiv{
      width:100% !important;
      height:450px
    }
    :global(.slick-current):hover .applyBtnDiv{
    transform: translateY(calc(100% - 23.04rem));

    }
    .applyBtnDiv{
        height: 350px;
        border-radius: 20px 20px 20px 20px !important;
    }
    }
    @media screen and (min-width: 1400) {
     .sidebar{
            height: calc(100vh - 400px) !important;
     }
        }
        @media screen and (max-width: 1200px) {
            .sidebar{
                height: calc(100vh - 150px) !important;

            }
               }