.modalDialog :global(.table){
    margin-bottom: 0 !important;
}

.modalDialog :global(.modal-content){
    height: calc(100vh - 100px) !important;

}

.customizeModal :global(.modal-content){
    margin: 30px;
    box-shadow: rgba(100, 100, 111, 0.2) -1px 3px 25px 20px;
    height: calc(100vh - 300px) !important;

}
.tableContainer{
    height:calc(100% - 90px)  !important;
}

@media screen and (max-width: 575px) {
    .modalDialog :global(.modal-content){
        height: 100vh !important;
    }
    .tableContainer{
        height:calc(100% - 67px)  !important;
    }
  }
@media screen and (max-width: 500px) {
    .modalDialog :global(.modal-body){
        padding:0 !important;
    }
  }
  @media screen and (max-width: 1500px) {
    .customizeModal :global(.modal-content){
        height: calc(100vh - 100px) !important;
    
    }
  }