

.subsCard{
    height: 100%;
    border-radius: 10px 10px 10px 10px !important;
   
    /* background-image: url(https://divicio.us/demos/demo/wp-content/uploads/2018/08/featured-bg.png),linear-gradient(180deg,#8300e9 0%,#e100ff 100%); */
    overflow: hidden;
    border-width: 0px !important;

    box-shadow: 0px 10px 50px 0px rgb(131 0 233 / 10%) !important;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s ease 0s;
    position: relative;
    max-width: 300px;
    /* min-width: 300px !important; */
}
.subsCardInActive{
    background-image: url(https://divicio.us/demos/demo/wp-content/uploads/2018/08/bg-violet.png);

}
.subsCardActive{
    background-image: url(https://divicio.us/demos/demo/wp-content/uploads/2018/08/featured-bg.png),linear-gradient(180deg,#8300e9 0%,#e100ff 100%);
    margin: -30px -1px 0 0;
    
}
@media (max-width: 992px) {
    .subsCardActive {
        margin-top: 20px;
    }
    .subsCardInActive{
  margin-bottom: 10px;
transition: none;
    
    }
  
  }
.subsCard:hover{
    transform: translateY(-20px);
    box-shadow: 0px 10px 50px 0px rgb(131 0 233 / 30%) !important;

}
.subsCardActive:hover{
    transform: none;

}
.subsh2{
    font-family: 'Alef',Helvetica,Arial,Lucida,sans-serif!important;
    font-size: 18px!important;
    font-weight: 500 !important;
}
.subsdiv{
    /* padding-top: 20px!important; */
    border-bottom: 0px;
    /* padding-bottom: 30px; */
    color:#2f1c6a !important;
    position: relative;
    border-style: solid;
    border: 0
}
.subsspan1{
    margin-left: -11.2031px;
    font-size: 18px;
    position: absolute;
}
.subsspan2{
    font-size: 1.7rem;
    padding-left: 15px;
    line-height: 1.03em;
}
.subsspan3{
    /* color: #8300e9!important; */
    /* font-weight: 700; */
    font-size: 14px;
}
.subsDetails{
    padding-top: 20px!important;
    /* padding: 0px 45px; */
    padding-left: 10px;
    text-align: left;
    margin-top: 20px;
    position: relative;
}
.subsul{
    list-style: none!important;
    padding: 0!important;
    line-height: inherit!important; 
}
.subsli{
    /* color: #8300e9!important; */
    /* font-weight: 700; */
    line-height: 1.6em;
    padding: 0 0 1.6em 14px;
    position: relative;
}
.subsli::before{
    content: "✓";
    padding-right:10px ;
    color:#36AF8D

}
.card-title{
    padding-top: 20px!important;
    padding-bottom: 24px!important;
    padding: 10px 0;
    position: relative;
}
.butondiv{
    text-align: center; 
    padding-left: 30px;
    padding-right: 30px;
}
  
 .customBtn{
    color: black !important;
    /* background-color: #F8AD00 !important; */
    border: 1px solid #F8AD00 !important;
    border-color: #F8AD00 !important;


 }   
 

 .changeFreqBtn{
    color: #ffffff!important;
    background-color:#8300e9  !important ;
    border-color: #8300e9 !important;
 }

 .upgradeBtn{
    color: #ffffff!important;
    background-color:green  !important ;
    border-color: green !important;

 }

 .freqP{
    font-size: 16px;
 }
  
 .susbsbtnModal{
    border-width: 2px!important;
    /* border-color: #8300e9 !important; */
    border-radius: 100px;
    background-color: white;
    padding: 0.3em 1em!important;
    color: #ffffff!important;
    box-shadow: 0px 2px 18px 0px rgb(0 0 0 / 30%);
    display: inline-block;
    position: relative;
    font-size: 16px;
    font-weight: 500;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    border: 2px solid;
    line-height: 1.7em!important;
    vertical-align: baseline;
 }

 .accordianHeader{
 border: 1px solid #DEDFE4;   
 }

 .accordianBody{
    border:none !important

 }

.susbsbtn{
    border-width: 2px!important;
    /* border-color: #8300e9 !important; */
    border-radius: 100px;
    /* background-color: #8300e9; */
    padding: 0.3em 1em!important;
    color: #ffffff!important;
    box-shadow: 0px 2px 18px 0px rgb(0 0 0 / 30%);
    display: inline-block;
    position: relative;
    font-size: 16px;
    font-weight: 500;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    border: 2px solid;
    line-height: 1.7em!important;
    vertical-align: baseline;
 }
.susbsbtn{
    border-width: 2px!important;
    /* border-color: #8300e9 !important; */
    border-radius: 100px;
    /* background-color: #8300e9; */
    padding: 0.3em 1em!important;
    color: #ffffff!important;
    box-shadow: 0px 2px 18px 0px rgb(0 0 0 / 30%);
    display: inline-block;
    position: relative;
    font-size: 16px;
    font-weight: 500;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    border: 2px solid;
    line-height: 1.7em!important;
    vertical-align: baseline;
}
.planBtn{
    border-radius:8px
}

.termsTable td{
    padding:1rem !important;
}


.common{
    color: #4414d3 !important;
    font-weight: 600;
}
.different{
    color: #e128bc !important;
    font-weight: 600;
}

.currentPlan{
    border-right: 1px   solid green;
    border-top:1px   solid green;
    border-left: 1px   solid green;
}
.planBtn{
    border-radius:8px
}

.termsTable td{
    padding:1rem !important;
}

.termsTableMobile td{
    padding:0.5rem !important;
}

.common{
    color: #4414d3 !important;
    font-weight: 600;
}
.different{
    color: #e128bc !important;
    font-weight: 600;
}

.currentPlan{
    border-right: 1px   solid green;
    border-top:1px   solid green;
    border-left: 1px   solid green;
}

.activeBtn {
    background-color: #8300e9 !important;
    color: white !important;
}

.pill{
    border: 1px solid #8300e9 !important;
}
@media screen and (min-width: 320px) and (max-width: 1024px) {
    .downgradeBtn {    
        /* color: #ffffff!important;
        background-color: #100146 !important ;
        border-color: #100146 !important; */
    
        font-size: 16px;
     }
   .subsh2{
    font-size: 16px !important;
   }
   .subsspan1{
    font-size: 14px !important;

   }
   .subsspan2{
    font-size: 33px;
   }
   .subsspan3{
    font-size: 14px
   }
   .subsul{
    font-size: 14px;
   }
   .subsDetails{
    padding-top:5px !important;
    padding-left:0px;
    margin-top: 10px;
   }
   .subsli{
    line-height: 1.4em;
   }
   .susbsbtn{
    font-size:14px
   }
}

@media screen and (min-width: 778px) and (max-width: 1100px) {


}
