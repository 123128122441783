.cartPage {
  position: fixed;
  top: 67px;
  bottom: 0;
  height: calc(100vh - 67px);
  z-index: 21;
  width: 75vw;
}

.cartPageOnSalesPage {
  right: 0;
  transform: translate(calc(100% - 200px), 0);
}
.cartPageOnOtherPage {
  right: -200px;
  transform: translate(100%, 0);
}

.cartOnFullCart {
  right: 0 !important;
  transform: translate(0, 0);
}

.cartContainer {
  display: flex;
  height: calc(100vh - 67px);
}

.transitionTransform {
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.fullViewBackdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 40;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.142);
}


@media screen and (max-width: 991px) {
  .cartPage.cartOnFullCart {
    top: 0;
    height: 100vh;
    width: 100vw;
  }
  .cartOnFullCart .cartContainer {
    display: flex;
    height: 100vh;
  }
  .cartPageOnSalesPage {
    transform: translate(100%, 0);
  }
  .cartOnFullCart {
    right: 0 !important;
    transform: translate(0, 0);
  }
}

@media screen and (max-height: 721px) {
  .cartPage.cartOnFullCart {
    top: 0;
    height: 100vh;
    width: 100vw;
  }
  .cartOnFullCart .cartContainer {
    display: flex;
    height: 100vh;
  }
}
