.pageContainer {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.itemViewsSection {
  width: 100%;
  height: calc(100vh - 131px);
  overflow: hidden;
  margin-right: 200px;
}

.itemViewsSection {
  width: 100%;
  height: calc(100vh - 131px);
  overflow: hidden;
}

.chooseproducts {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #39414a;
  height: 29px;
}

.statusDropdown {
  background: transparent;
  margin-left: 0px !important;
}

.statusDropdown > button:active {
  background: transparent !important;
}

.viewModeBtnGroup button:hover {
  background: #deebff !important;
  color: #80858c !important;
}
.viewModeBtnGroup button:global(.active):hover {
  color: var(--bs-btn-active-color) !important;
  background-color: var(--bs-btn-active-bg) !important;
  border-color: var(--bs-btn-active-border-color) !important;
}

@media screen and (max-width: 575px) {
  .pageContainer {
    height: 100% !important;
    overflow-y: auto;
  }
  .itemViewsSection {
    height: 100% !important;
  }
}
@media screen and (max-width: 767px) {
  .itemViewsSection {
    height: calc(100vh - 200px);
  }
  .chooseproducts {
    font-size: 16px;
  }
  .chooseproducts + div {
    font-size: 14px;
  }
}
@media screen and (max-width: 991px) {
  .itemViewsSection {
    margin-right: 0px;
    height: calc(100vh);
  }
}
