.printComponent {
  margin: 0;
  padding: 0;
}

.printComponent2 {
  margin: 3rem;
  padding: 0;
}

.invoicePrint {
  margin: 3rem;
}

.shopName {
  color: black !important;
}

.shopDetails {
  font-weight: 400 !important;
  margin: 0 !important;
  color: black !important;
}

.thermalPrinterTable th {
  font-size: 14px !important;
  color: black !important;
}

.thermalPrinterTable td {
  font-size: 12px !important;
  font-weight: 700 !important;
  color: black !important;
}

.totals {
  font-size: 12px !important;
  margin: 0 !important;
  font-weight: 400 !important;
  color: black !important;
}

.invoiceTerms {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: black !important;
}

.extraTh {
  padding: 1rem;
}

@media screen and (max-width: 800px) {
  .printComponent2 {
    margin: 2rem;
    padding: 0;
  }

  .invoicePrint {
    margin: 0rem;
    padding: 1rem !important;
  }
}

@media print {

  html,
  body {
    height: inherit;
    overflow: initial;
    page-break-inside: always;
    break-inside: auto;
    font-family: Arial !important;
  }

  .thermalPrinterTable table {
    page-break-after: auto;
    font-family: Arial !important;
  }

  .thermalPrinterTable tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  .thermalPrinterTable td {
    page-break-inside: avoid;
    page-break-after: auto;
    font-size: 8px !important;
    color: black !important;
    font-weight: 600 !important;
    letter-spacing: 0.1rem !important;
  }

  .thermalPrinterTable th {
    page-break-inside: avoid;
    page-break-after: auto;
    font-size: 8px !important;
    color: black !important;
  }

  .extraTh {
    padding: 0.5rem;
  }

  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group;
  }

  .printComponent2 {
    margin: 1rem 0.5rem;
    padding: 0;
  }

  .shopInfo {
    display: block !important;
  }

  .address {
    display: block !important;
  }

  .invoicePrint {
    margin: 1rem !important;
    padding: 0rem !important;
  }

  .thermalInvoice {
    font-family: Arial !important;

    @page {
      size: 80mm 250mm;
    }
  }

  .shopName {
    font-family: Arial !important;
    color: black !important;
    font-size: 20px !important;
  }

  .shopDetails {
    font-size: 10px !important;
    font-weight: 600 !important;
    margin: 0 !important;
    font-family: Arial !important;
    color: black !important;
  }

  .totals {
    font-size: 8px !important;
    margin: 0 !important;
    font-weight: 600 !important;
    font-family: Arial !important;
    color: black !important;
  }

  .invoiceTerms {
    font-size: 9px !important;
    font-family: Arial !important;
    color: black !important;
    font-weight: 600 !important;
  }
}