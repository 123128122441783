.cards {

    /* width: 20rem; */
    height: 115px !important;
    background: #FFFFFF !important;
    box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5) !important;
    border: 0 !important;
}
.cardBodyContent{
    padding: 25px !important;
}
.cardstxt {
    font-weight: 400 !important;
    font-size: 12px !important;
    color: #686E76 !important;
    line-height: 100% !important; /* 12px */
    letter-spacing: -0.12px !important;
}

.widgetIconDiv{
    height: 100% !important;
    padding-right: 25px !important;
}

.widgetIcon{
    width: 60px !important;
    height: 60px !important;
}
.cartCount{
    color: #100146 !important;
    font-family: Cabin !important;
    font-size: 32px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 100% !important; /* 32px */
    letter-spacing: -0.32px !important;
}
.collapseButton{
    color: #000 !important;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 18px !important; /* 128.571% */
    background: #FFF !important;
    border-radius: 0 !important;
    text-align: start !important;
    height: 50px !important;

}
.collapseButton:active{
    background: #ffffffd9 !important;
}
.collapseDiv{
    border:  1px solid #DEE2E6 !important;

}
@media screen and (max-width: 575px) {
    .cards{
        border-radius: 0 !important;
        padding: 0 !important;
        height: 80px !important;
        border-top: 1px solid #DEDFE4 !important;
    }
    .cardBodyContent{
        padding: 20px !important;
    }
    .widgetIconDiv{
        padding-right: 20px !important;
    }
    .widgetIcon{
        width: 42px !important;
        height: 42px !important;
    }
}