.widgetCard {
    height: 180px !important;
    /* max-width: 370px; */
    box-shadow: 0px 10px 60px rgb(226 236 249 / 50%);
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.textWrap {
    /* white-space: pre; */
    font-size: 26px !important;
    line-height: 32px;
    letter-spacing: -0.01rem;
}

@media screen and (max-width: 500px) {
    .widgetCard {
        height: 100% !important;
        max-width: 100%;
        padding-top: 0rem;
        padding-bottom: 0rem;
    }
    .widgetCard img {
        width: 50px !important;
        height: 50px !important;
        margin-left: 5px;
    }
    .widgetCard h3 {
        font-size: 22px !important;
        margin: 0;
    }
    .widgetCard :global(.card-title) {
        font-size: 18px !important;
        margin: 0 !important;
    }
}