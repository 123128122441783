.brdrd .table tr > td:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  color: #100146 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}
.brdrd table {
  border-radius: 0px !important;
  border-color: #dedfe4 !important;
  border: 1px solid #dedfe4;
  border-top: 0px !important;
}
.brdrd .table {
  margin-bottom: 0px !important;
}

.table_main > tbody td {
  font-size: 15px !important;
}

.table_main tbody > tr:nth-of-type(odd) > * {
  background: rgb(248, 246, 255) !important;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 15px !important;
}

@media only screen and (max-device-width: 768px) {
  .table_main tr > th:first-child,
  .table_main tr > td:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    width: 100%;
    min-width: 75px;
    z-index: 1;
  }


  .table_main tr > th:first-child::after,
  .table_main tr > td:first-child::after {
    content: "";
    position: absolute;
    right: 0;
    display: inline-block;
    top: 0px;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  .table_main tr > th:first-child {
    background: #ffffff;
  }
  
}

@media screen and (max-width: 500px) {
  .table_main > tbody td {
    font-size: 13px !important;
  }
}

/* For screen size greater than 500 and less than 768 */
@media screen and (min-width: 500px) and (max-width: 768px) {
  .table_main > tbody td {
    font-size: 14px !important;
  }
}

.table_main > thead > tr > th:first-child {
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.table_main > tbody > tr > td:first-child {
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.report_subtitle {
  font-size: 13px !important;
}

/* for screen size less than 768px */
@media screen and (max-width: 768px) {
  .report_subtitle {
    font-size: 12px !important;
  } 
}