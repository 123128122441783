.formContent {
    z-index: 1;
}

.addForm {
    padding: 0 50px !important;
}
.form {
    width: 50% !important;
    box-shadow: 0px 20px 45px #f0edf6 !important;
}
.formMain {
    padding: 0 3rem !important;
}

.passwordInput {
    position: relative;
}
.passwordInput :global(.form-control):invalid {
    background-position: right calc(0.375em + 1.5rem) center !important;
  }
.passwordValidate {
    background-image: none !important;
}

.passwordInput svg {
    bottom: 13px;
    position: absolute;
    right: 10px;
    width: 16px;
}

.passwordField {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.logoemail{
    position:relative;
    left:17rem;
     margin-bottom:-4.8rem

}

.Checkbox label {
     font-size: 13px;
}

@media screen and (max-width: 1024px) {
    .addForm {
        padding: 0 10px !important;
    }
    .form {
        width: 100% !important;
    }
 }

@media screen and (max-width: 768px) {
    .addForm {
        padding: 0 10px !important;
    }
    .form {
        width: 100% !important;
    }
    .formMain {
        padding: 0 1rem !important;
    }
    .logoemail{
        position:relative;
        left:16rem;
         margin-bottom:-4.8rem
    
    }
}