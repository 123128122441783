.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 250px;
  margin: 0 auto;
  border: 1px solid #dedfe4;
  box-sizing: border-box;
  border-radius: 6px;
}

.close {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #9e3ce7;
  height: 20px;
  width: 20px;
}

.qr_container {
  height: 192px !important;
  width: 192px !important;
  border: 2px solid #dedfe4;
  box-sizing: border-box;
  border-radius: 6px;
}

.qr_container > canvas {
  object-fit: contain !important;
  height: 100% !important;
  width: 100% !important;
}
