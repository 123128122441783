body {
  font-family: "Cabin", sans-serif;
  background: #f5f6fa !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
span {
  font-family: "Cabin", sans-serif;
}

p,
span,
a {
  letter-spacing: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

.link {
  color: #8834ff;
  text-decoration: none;
}

.link:hover {
  color: #8834ff;
}

.text-success {
  color: rgba(36, 153, 62, 1) !important;
}

.text-danger {
  color: rgba(240, 104, 129, 1) !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  padding-right: 2rem !important;
}

input:focus {
  box-shadow: none !important;
  border: 1px solid #d957f9 !important;
}

input[type="checkbox"]:checked {
  background-color: #da57f9;
  border: 1px solid #da57f9;
}

input[type="radio"]:checked {
  background-color: #da57f9;
  border: 1px solid #da57f9;
}

input::placeholder,
select::placeholder {
  /* font-style: italic; */
  font-size: 14px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  --moz-appearance: textfield;
}

.cardShadow {
  box-shadow: 0px 10px 60px rgb(226 236 249 / 50%);
}

.btn {
  padding: 0.5rem 0.75rem;
  /* border: none;
  border-color: none; */
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
}

.btn-primary {
  background: linear-gradient(180deg, #a93bff 0%, #8834ff 100%);
  color: #fff;
  border: none;
  /* border-color: none; */
}

.btn-primary:active {
  background: linear-gradient(180deg, #aa3bffe4 0%, #8934ffe9 100%) !important;
}

.btn-outline-primary {
  color: #8834ff;
  border: 1px solid #8834ff;
}

.btn-outline-primary:hover {
  background: linear-gradient(180deg, #a93bff 0%, #8834ff 100%);
  color: #fff;
  border: 1px solid #8834ff;
}

button:focus {
  box-shadow: none !important;
  /* border: none; */
  /* border-color: none !important; */
}

input:invalid[focused="true"] {
  border: 1px solid red !important;
}

input:invalid[focused="true"] ~ div {
  display: block;
  color: red;
}

.shadowMain {
  box-shadow: 0px 2px 4px rgba(39, 53, 70, 0.1);
}

.table-striped > tbody > tr:nth-child(odd) > .total_td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #fff !important;
}

.purple:focus {
  color: #5932ea !important;
  background-color: #f5f6fa !important;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
  color: #39414a;
  background: rgb(248, 246, 255);
  box-shadow: rgb(0 0 0 / 0%) 0px 0p;
}
.table-striped > tbody > tr:nth-of-type(even) > * {
  color: #39414a;
  background: white;
}

.dark-text {
  color: #000;
}

/* .table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #f8f6ff !important;
}
.css-19h6c76-option {
  background-color: #deebff !important;
}
.css-gduu8s-option {
  background-color: #deebff !important;
} */
/* React Select */
.react-select-container__control--is-disabled {
  background: #e9ecef;
}
.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-b62m3t-container {
  width: 100%;
  max-width: 100%;
}

.react-select-container__control {
  display: flex;
}
.react-select-container__input-container input {
  border: 0px !important;
}
/* div:has(input) > .react-select-container__control:not(:has(~ input[type="hidden"])) { */
div:has(> .react-select-container__control):not(.form-control) > .react-select-container__control {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.13rem !important;
}

.react-select-container__control--menu-is-open:has(~ input[type="hidden"]) {
  border: 0 !important;
  padding: 0 !important;
}

.react-select-container__menu:has(~ input[type="hidden"]) {
  left: -0.13rem;
}

.form-control:has(> .react-select-container__control) {
  padding: 0.13rem 1rem 0.13rem 0rem !important;
}
.form-control:has(> .react-select-container__control):has(.react-select-container__control--is-disabled) {
  background: #e9ecef !important;
}

.scrollable_multiselect .react-select-container__value-container {
  overflow: auto;
}
.react-select-container__value-container::-webkit-scrollbar {
  width: 0.3em;
}
.react-select-container__value-container::-webkit-scrollbar-thumb {
  background-color: rgba(178, 183, 203, 1);
  outline: 1px solid rgba(222, 223, 228, 1);
  border-radius: 6px;
}
.react-select-container__value-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* Validation */
.invalid-text {
  display: none;
  color: red;
}

input[data-touched="true"]:invalid,
select[data-touched="true"]:invalid,
textarea[data-touched="true"]:invalid {
  border: 1px solid red !important;
}

input[data-touched="true"]:invalid ~ .invalid-text,
select[data-touched="true"]:invalid ~ .invalid-text,
textarea[data-touched="true"]:invalid ~ .invalid-text {
  display: block;
}

.modal-content {
  border: none !important;
  border-radius: 6px;
}

/* Tabs */
.nav-tabs .nav-link {
  color: #80858c;
  border: 0;
}

.nav-tabs .nav-link:hover {
  color: #8834ff;
  border: 0;
  border-bottom: 2px solid #8834ff;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #8834ff;
  border: 0;
  border-bottom: 2px solid #8834ff;
}

.dropdown-toggle::after {
  display: none;
}

/* Markdown */
.jodit-status-bar-link {
  display: none;
}

.tox-statusbar__branding {
  display: none;
}
.tooltipp {
  position: relative;
  display: flex;
}
.tooltipp .tooltiptext {
  visibility: hidden;
  width: 5rem;
  background-color: #9537ff;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 2px;
  font-size: 12px;
  /* padding: 20px; */
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 26%;
  right: 104%;
}
.tooltipp:hover .tooltiptext {
  visibility: visible;
}

.tooltippp {
  position: relative;
  display: flex;
}
.tooltippp .tooltiptextfilter {
  visibility: hidden;
  width: 5rem;
  background-color: #9537ff;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 2px;
  font-size: 12px;
  /* padding: 20px; */
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 3rem;
  right: 1rem;
}
.tooltippp:hover .tooltiptextfilter {
  visibility: visible;
}

.fs-17 {
  font-size: 17px;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-11 {
  font-size: 11px !important;
}
.fs-10 {
  font-size: 10px !important;
}

.legend-header-font {
  font-size: 14px;
}
.legend-body-font {
  font-size: 13px;
}

/* for screen size b/w 500 and 1024 */
@media screen and (min-width: 500px) and (max-width: 1024px) {
  .legend-header-font {
    font-size: 13px;
  }
  .legend-body-font {
    font-size: 12px;
  }
}

/* for screen size less than 500 */
@media screen and (max-width: 500px) {
  .legend-header-font {
    font-size: 12px;
  }
  .legend-body-font {
    font-size: 12px;
  }
}

/* DateRangePicker Wrapper Sytel */
.react-bootstrap-daterangepicker-container {
  width: 100%;
}

/*STYLE for BACK BUTTON*/
.backFormButton {
  border-radius: 4px;
  background-color: #a439ff;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  padding: 8px;
  width: 75px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 3px;
}

.backFormButton span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.backFormButton span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  left: -16px;
  transform: rotate(180deg);
  transition: 0.5s;
}

.backFormButton:hover span {
  padding-left: 20px;
}

.backFormButton:hover span:after {
  opacity: 1;
  left: 0;
}

/* .tox-tinymce{

    z-index: -100 !important;
  } */
/*END OF --------STYLE for BACK BUTTON*/

/* .react-select > div:first-of-type + div{
    z-index: 3;
} */
.tox-editor-header {
  z-index: inherit !important;
}

.Toastify__progress-bar {
  background-color: #9537ff !important;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #ced4da !important;
  background-image: none !important;
}

.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: black;
}
.form-check-input.is-valid,
.was-validated .form-check-input:valid {
  border-color: #da57f9;
}

.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: #da57f9;
}
.cancel_span {
  text-decoration: underline;
}
.cancel_span:hover {
  color: #9537ff;
  cursor: pointer;
}
.css-hw1ikw-option:active {
  background-color: rgb(178, 212, 255);
}
/* pricing Plans */
.SubsButtons {
  border: none;
  background-color: white;
  color: #5932ea;
  border-color: none;
  border-radius: 21px;
  font-weight: 500;
  font-size: 16px;
  box-sizing: border-box;
  padding: 10px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}
.SubsButtonsActive {
  background: linear-gradient(180deg, #a93bff 0%, #8834ff);
  color: white;
}
.SubsButtonsinActive {
  background-color: white;

  color: #5932ea;
}
.SubsButtons:hover {
  background: linear-gradient(180deg, #a93bff 0%, #8834ff);
  color: white;
}

/* .card-title {
  padding-top: 20px !important;
  padding-bottom: 24px !important;
  padding: 10px 0;
  position: relative;
} */

.font700 {
  font-weight: 700;
}
.inactiveColor {
  color: #8300e9 !important;
}
.activeColor {
  color: white;
}

.plansRowinActive {
  margin-bottom: 2.75%;
  visibility: hidden;
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.4s ease, transform 0.4s ease;
  animation-timing-function: linear;
  animation-duration: 0.2s;
  /*  
  animation-name: fadeInUp;
  animation-duration: 1s;
  animation-fill-mode: forwards */
  /* transition: opacity 1s ease, transform 1s ease; */
}
.plansRowActive {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  transition: opacity 1s ease, transform 1s ease;
  animation-timing-function: linear;
  animation-duration: 0.2s;
}

.colored-row td {
  background-color: #f8f6ff !important;
  --bs-table-accent-bg: rgba(0, 0, 0, 0.05) !important;
}

/* .table-striped > tbody > .colored-row > tr:nth-child(odd) > td, .table-striped > tbody > tr:nth-child(odd) > th {

} */
.strippedColumn {
  background: #f8f6ff !important;
}
.fs-13 {
  font-size: 13px !important;
  font-weight: 600;
}
.fs-16 {
  font-size: 16px !important;
  font-weight: 600;
}
.fs-20 {
  font-size: 20px;
}
.dashboard_calender img {
  margin-top: -3px;
}

@media screen and (max-width: 500px) {
  .dashboard_calender img {
    margin-top: -7px;
  }
  .btn {
    font-size: 13px;
  }
  .modal-body {
    padding: 1rem 0.8rem !important;
  }
}
.inve-width {
  width: 30px;
}
/* .form-select {
  color: gray;
} */
.form-select::-moz-placeholder {
  color: gray;
}
.css-tqomhi-option {
  background-color: #f5f6fa !important;
}
.css-lhwysk-option {
  background-color: #f5f6fa !important;
}

.image {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
.image :hover {
  background-color: rgba(0, 0, 0, 0.8) !important;

  transition: background-color 0.2s ease-in-out !important;
}

.opacity img {
  opacity: 0.5;
}
.cursor-pointer {
  cursor: pointer !important;
}

.customerSalesModal .modal-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
}

.customerSalesModal table {
  border: 1px solid #dedfe4 !important;
}

.customerSalesModal .searchIcon {
  position: absolute;
  top: 7px;
  left: 3px;
}
.customerSalesModal .searchInput {
  padding-left: 2rem !important;
}

.customerSalesModal tr th:first-child,
.customerSalesModal tr td:first-child {
  padding-left: 20px !important;
}

.customerSalesModal tr th:last-child,
.customerSalesModal tr td:last-child {
  border: 0px !important;
}

.customerSalesModal tr td:last-child div {
  justify-content: center !important;
}

.card-main {
  width: 100%;
  display: block;
  position: relative;
  background-color: white;
  border-radius: 4px;
  margin: 12px;
  text-decoration: none;
  overflow: hidden;
  border: 1px solid #f2f8f9;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
}
.card-main:hover {
  transition: all 0.2s ease-out;
  border: 1px solid #cccccc;
  background-color: white;
}
.card-main:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #a93bff;
  height: 32px;
  width: 32px;
  border-radius: 32px;
}
.reports-card {
  background-color: white;
}
.profit-cards {
  background-color: transparent;
}
.card-main-2 {
  width: 100%;
  display: block;
  position: relative;
  background-color: white;
  border-radius: 4px;
  margin: 12px;
  text-decoration: none;
  overflow: hidden;
  border: 1px solid #f2f8f9;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
}
.card-main-2:hover {
  transition: all 0.2s ease-out;
  /* border: 1px solid #cccccc; */
  background-color: white;
}

.slide:before {
  content: "";
  position: absolute;

  border-radius: 32px;
  transform: scale(2);
  transform-origin: 50% 50% !important;
  transition: transform 0.15s ease-out;
}

.reports-card .icon {
  position: absolute;

  top: 0;

  left: 0;

  width: 100%;

  height: 100%;

  background: white;
}
.reports-card .icon .fa {
  position: absolute;

  top: 50%;

  left: 50%;

  transform: translate(-50%, -50%);

  font-size: 80px;

  color: #fff;
}

.reports-card .slide {
  /* width: 300px; */

  /* height: 200px; */

  transition: 0.5s;
}
.reports-card .slide.slide1 {
  width: 100%;
  position: relative;

  display: flex;

  justify-content: center;

  align-items: center;

  z-index: 100;
  padding: 30px;

  transition: 0.7s;

  transform: translateY(0px);
}
.reports-card .slide.slide2 {
  width: 96% !important;
  position: absolute;
  margin-left: 22px;
  display: flex;

  justify-content: center;

  align-items: center;

  padding: 10px;
  box-sizing: border-box;
  background: #a93bff;
  color: white;
  transition: 0.8s;
  z-index: 10;
  transform: translateY(-140px);

  /* box-shadow: 0 20px 40px rgba(0,0,0,0.4); */
}
.reports-card:hover .slide.slide1 {
  transform: translateY(0px);
}
.reports-card:hover .slide.slide2 {
  transform: translateY(-28px);
}
.reports-card .slide.slide2::after {
  content: "";

  position: absolute;

  width: 30px;

  height: 4px;

  bottom: 10px;

  left: 50%;

  left: 50%;

  transform: translateX(-50%);

  background: #a93bff;
}

@media screen and (max-width: 991px) {
  .customerSalesModal .modal-dialog {
    max-width: 95%;
  }
}

@media screen and (max-width: 575px) {
  .customerSalesModal .modal-dialog {
    max-width: 100%;
    width: 100%;
    margin: 0;
  }
}
.css-26l3qy-menu {
  z-index: 1000 !important;
}
.toggle.btn:focus {
  background: #f0f0f0 !important; /* Set your desired background color here */
  color: #000; /* Set your desired text color here */
}

.pac-container
/* .pac-logo.hdpi */ {
  z-index: 10000 !important;
}
.btn-check:focus + .btn:focus.btn-secondary {
  background: #6c757d !important;
}

.rjsf .btn {
  margin-top: 20px;
}

.rjsf .btn-info {
  background: linear-gradient(180deg, #a93bff 0%, #8834ff 100%);
  color: #fff;
  border: none;
  width: 25%;
}
.control-label {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}
.slick-center {
  height: 450px;
  outline: none;
  width: 350px !important;
  z-index: 111111;
  position: relative;
  box-shadow: black;
  box-shadow: rgba(100, 100, 111, 0.2) -1px 3px 25px 7px;
  border-radius: 20px;
  opacity: 1 !important;
  transform: scale3d(1, 1, 1) !important;
  /* margin-top: 5px;
  margin-bottom: 15px; */
  transition: all 0.3s ease-in-out;
  /* background-image: url("https://img.freepik.com/free-photo/autumn-leaf-falling-revealing-intricate-leaf-vein-generated-by-ai_188544-9869.jpg"); */
}
.slick-center img:hover {
}
.slick-center img {
  /* width: -webkit-fill-available; */
  width: 350px;
  border-radius: 20px;
}
@media screen and (max-width: 1024px) {
  .slick-center {
    width: 257px !important;
    height: 330px;
  }
}

.slick-slide {
  opacity: 0.3;
  transform: scale3d(0.8, 0.8, 1);
  transition: all 0.3s ease-in-out;
}
.slick-current:hover .applyBtnDiv {
  transform: translateY(calc(100% - 5.2rem));
  opacity: 1;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.skeleton {
  background: linear-gradient(90deg, hsl(214, 6%, 75%), hsl(210, 33%, 93%), hsl(213, 9%, 81%));
  background-size: 200%;
  animation: loader 1s infinite reverse;
}
@keyframes loader {
  from {
    background-position: -100% 0;
  }
  to {
    background-position: 100% 0;
  }
}

/* .table-container */
.table-container::-webkit-scrollbar {
  width: 0.3em;
}
.table-container::-webkit-scrollbar-thumb {
  background-color: rgba(178, 183, 203, 1);
  outline: 1px solid rgba(222, 223, 228, 1);
  border-radius: 6px;
}
.table-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
